// import {
//   stripUnit,
// } from 'polished'

import EASE from './ease'
import INITIAL_PIC from './initial-pic'

// Font Family
const LIGHT_FONT = 'FuturaLight, "Helvetica Neue Light", Helvetica, Arial, sans-serif'
const BOOK_FONT = 'FuturaBook, "Helvetica Neue", Helvetica, Arial, sans-serif'
const MEDIUM_FONT = 'FuturaMedium, "Helvetica Neue", Helvetica, Arial, sans-serif'
const DEMI_FONT = 'FuturaDemi, "Helvetica Neue", Helvetica, Arial, sans-serif'

// COLORS

const LIGHT = 'hsl(300, 1%, 94%)'
// const DARK_GRAY = 'hsl(300, 1%, 12%)'
const DARK_GRAY = 'hsl(300, 2%, 10%)'
const DARKER_GRAY = 'hsl(300, 1%, 16%)'
// const GRAY = 'hsl(300, 1%, 20%)'
const GRAY = 'hsl(300, 1%, 16%)'
const LIGHT_GRAY = 'hsl(300, 1%, 28%)'
const LIGHTER_GRAY = 'hsl(300, 1%, 50%)'
const ACCENT_COLOR = 'hsl(171, 100%, 36%)'
const ACCENT_COLOR_LIGHTER = 'hsl(171, 100%, 40%)'
const ACCENT_COLOR_DARKER = 'hsl(171, 97%, 31%)'
// const ACCENT_COLOR_DARKER = 'hsl(171, 97%, 23%)'
const SHADOW = '0px 0px 6px hsla(300, 1%, 8%, .65)'

// Buttons
const BTN_SMALL_W = '32px'
const BTN_SMALL_H = '32px'
const BTN_SMALL_W_S = '30px'
const BTN_SMALL_H_S = '30px'

// Header
const HEADER_H = `${20 + parseInt(BTN_SMALL_H, 10)}px`
// const HEADER_H = '50px'
const HEADER_H_S = `${19 + parseInt(BTN_SMALL_H_S, 10)}px`
const LOGO_W = '132px'

// LAYOUT
const PAD_LR = '25px'
const PAD_LR_S = '15px'
const PAD_LR_XS = '10px'
const PAD_T = '70px'
const PAD_B = '60px'
const PAD_T_S = '40px'
const PAD_B_S = '25px'
const BORDER_RAD = '3px'

// TOOLBAR
const TOOLBAR_R = `calc(${BTN_SMALL_W} + ${PAD_LR} + 8px)`
const TOOLBAR_R_S = `calc(${BTN_SMALL_W_S} + ${PAD_LR_S} + 5px)`

// Home
const SIDEBAR_W = '315px'
const SIDEBAR_W_M = '245px'
const SIDEBAR_W_S = '100%'

const SIDEBAR_PAD_R = '20px'
const SIDEBAR_ITEM_PAD = '22px'
// const STAGE_W = `calc(100% - ${SIDEBAR_W} - ${SIDEBAR_PAD_R})`
const STAGE_W = `calc(100% - ${SIDEBAR_W})`
const STAGE_W_M = `calc(100% - ${SIDEBAR_W_M})`

const INPUT_H = '28px'
const INPUT_H_S = '30px'
const INPUT_W = '45%'
const INPUT_MARGIN_B = '8px'
const INPUT_BORDER = '2px'

const BTN_ICON = {
  MARKUP: 'iconMarkup',
  UPLOAD: 'iconUpload',
  COPY: 'iconCopy',
  PLAY: 'iconPlay',
  CODE: 'iconCode',
  CLOSE: 'iconClose',
}

// Code
const CODE_H = '95%'

// Footer
const FOOTER_H = '60px'

const MAIN_CONTENT_H = `calc(100vh - ${FOOTER_H} - ${HEADER_H})`

const FADED = '.3'

const BTN_BIG = {
  height: '36px',
}

// MEDIA QUERIES
const MEDIA = {
  XS: 'screen and (max-width: 20em)',
  // SMALL: 'screen and (min-width: 20.063em) and (max-width: 47.938em)',
  SMALL: 'screen and (max-width: 47.938em)',
  NOT_SMALL: 'screen and (min-width: 48em)',
  NOT_LARGE: 'screen and (max-width: 60em)',

  MEDIUM: 'screen and (min-width: 48em) and (max-width: 60em)',
  LARGE: 'screen and (min-width: 60em)',
  XL: 'screen and (min-width: 75em)',
  XXL: 'screen and (min-width: 100em)',
  ORIENT_H: 'screen and (max-height: 480px) and (orientation: landscape)',
}

// SEO
const SITE_URL = 'https://svgartista.net'
const SITE_IMAGE = `${SITE_URL}/svgartista-media.png`
const SITE_TITLE = 'SVG Artista'
const SITE_TITLE_DIVIDER = '-'

// eslint-disable-next-line max-len
const SITE_DESCRIPTION = 'SVG Artista is a free tool that helps you easily create amazing SVG drawing animations by animating stroke and fill properties of your SVG graphics.'
// const SITE_DESCRIPTION = 'SVG Artista is a tool that helps you animate stroke and fill properties in your SVG images with plain CSS code. It supports path, line, polyline, rect, circle, ellipse and polygon elements. SVG Artista generates the necessary CSS and updates the SVG code so that it can be easily animated by assigning .active class to the SVG element.'

// eslint-disable-next-line max-len
const SITE_KEYWORDS = ['svg animation', 'svg drawing', 'css animation', 'code generator', 'svg', 'artista', 'artist', 'css', 'animation', 'drawing', 'generator', 'transition', 'path', 'stroke', 'fill', 'dasharray', 'dashoffset']

const OG_IMAGE_TYPE = 'image/png'
const FACEBOOK_APP_ID = '495596217683187'
const TWITTER_ACCOUNT = '@sergej108'

// SOCIAL
const SOCIAL_SHARE_TITLE = encodeURIComponent('SVG Artista')
const SOCIAL_SHARE_TEXT = encodeURIComponent('Create your own SVG drawing animation with SVG Artista')
const SOCIAL_SHARE_TOOLTIP = 'Share the love for SVG Artista'

// Ads
const ADS_REFRESH_INTERVAL = 8000 // 8 seconds
const BSA_REFRESH_INTERVAL = 30000 // 30 sec
// const BSA_REFRESH_INTERVAL = 60000 // 1 minute
// const BSA_REFRESH_INTERVAL = 2000 // 2 sec
const ADS_CARBON_ID = 'CE7D62QJ'
const ADS_CARBON_CUSTOM_ID = 'CE7D653Y'
const ADS_CARBON_PLACEMENT = 'svgartistanet'

export {
  EASE,
  INITIAL_PIC,
  LIGHT_FONT,
  BOOK_FONT,
  MEDIUM_FONT,
  DEMI_FONT,
  LIGHT,
  DARK_GRAY,
  DARKER_GRAY,
  GRAY,
  LIGHT_GRAY,
  LIGHTER_GRAY,
  ACCENT_COLOR,
  ACCENT_COLOR_LIGHTER,
  ACCENT_COLOR_DARKER,
  PAD_LR,
  PAD_LR_S,
  PAD_LR_XS,
  PAD_T,
  PAD_T_S,
  PAD_B,
  PAD_B_S,
  BORDER_RAD,
  HEADER_H,
  HEADER_H_S,
  TOOLBAR_R,
  TOOLBAR_R_S,
  LOGO_W,
  SIDEBAR_W,
  SIDEBAR_W_M,
  SIDEBAR_W_S,
  SIDEBAR_PAD_R,
  SIDEBAR_ITEM_PAD,
  STAGE_W,
  STAGE_W_M,
  INPUT_H,
  INPUT_H_S,
  INPUT_W,
  INPUT_BORDER,
  INPUT_MARGIN_B,
  BTN_SMALL_H,
  BTN_SMALL_H_S,
  BTN_SMALL_W,
  BTN_SMALL_W_S,
  BTN_ICON,
  FOOTER_H,
  MAIN_CONTENT_H,
  SHADOW,
  BTN_BIG,
  CODE_H,
  FADED,
  SITE_URL,
  SITE_TITLE,
  SITE_TITLE_DIVIDER,
  SITE_DESCRIPTION,
  SITE_KEYWORDS,
  OG_IMAGE_TYPE,
  FACEBOOK_APP_ID,
  TWITTER_ACCOUNT,
  SITE_IMAGE,
  MEDIA,
  SOCIAL_SHARE_TITLE,
  SOCIAL_SHARE_TEXT,
  SOCIAL_SHARE_TOOLTIP,
  ADS_REFRESH_INTERVAL,
  ADS_CARBON_ID,
  ADS_CARBON_CUSTOM_ID,
  ADS_CARBON_PLACEMENT,
  BSA_REFRESH_INTERVAL,
}
