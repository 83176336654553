import React from 'react'
import PropTypes from 'prop-types'
import {
  SidebarInputWrapper,
  SidebarInput,
  SidebarLabel,
} from '../../styles/layout'


const Input = ({ label, ...rest }) => (
  <SidebarInputWrapper>
    <SidebarLabel>
      {label}
      <SidebarInput type="text" {...rest} />
    </SidebarLabel>
  </SidebarInputWrapper>
)

Input.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
}

export default Input
