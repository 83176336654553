import { createGlobalStyle } from 'styled-components'
import {
  DARK_GRAY,
  // GRAY,
  LIGHT,
  LIGHT_FONT,
  // BOOK_FONT,
  DEMI_FONT,
  MEDIUM_FONT,
  ACCENT_COLOR,
  INPUT_BORDER,
  // LIGHT_GRAY,
  LIGHTER_GRAY,
  MEDIA,
  // ACCENT_COLOR_DARKER,
  // GRAY,
  BOOK_FONT,
  ACCENT_COLOR_LIGHTER,
  // GRAY,
  // LIGHT_GRAY,
} from '../constants'

import LogoPayPal from '../images/paypal-logo.svg'


const GlobalStyles = createGlobalStyle`

  html {
    height: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  body {
    height: 100%;
     /* background-color: ${DARK_GRAY}; */
    background-color: ${LIGHT};
    color: ${DARK_GRAY};
    font-family: ${LIGHT_FONT};
  }

  a {
    color: ${LIGHT};
    text-decoration: none;
    font-weight: normal;
    
    &:hover {
      color: ${ACCENT_COLOR};
    }
  }


  ul, ol {
    list-style: none;
  }

  p, ul, ol,dd {
    font-family: 'HelveticaNeue-Light','Roboto-Thin',sans-serif-light, sans-serif;
    /* font-size: 1.25rem; */
    font-size: 1rem;
    line-height: 1.7;
    letter-spacing: 1px;
     /* color: ${LIGHT}; */
    padding: 0 0 1.5rem;
    margin: 0;

    @media ${MEDIA.SMALL} {
      padding-bottom: 1.25rem;
    }
  }

  h1 + p {
    padding-top: 1.5rem;
    
    @media ${MEDIA.MEDIUM} {
      padding-top: 1rem;
    }
    @media ${MEDIA.SMALL} {
      padding-top: 0;
    }
  }


  h1, h2, h3, h4, h5, h6 {
    display: block;
    font-family: ${MEDIUM_FONT};
    font-weight: normal;
    /* color: ${LIGHT}; */
    line-height: 1.05;
    padding: 0;
    margin: 0;
  }

  .page-content {
    
    h1 {
      font-size: 3.5625rem;
      line-height: 1.05;
      padding-bottom: 1.5625rem;
      padding-top: 0;
      /* margin-bottom: 1.5625rem; */
      width: 80%;
      font-family: ${DEMI_FONT};
      position: relative;

      &:first-child {margin-top: 0;}

      /* &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 80px;
        height: 5px;
        background-color: ${ACCENT_COLOR};
      } */

      @media ${MEDIA.LARGE} {
        width: 65%;
      }
      
      @media ${MEDIA.MEDIUM} {
        font-size: 3.125rem;
      }

      @media ${MEDIA.SMALL} {
        font-size: 2.125rem;
        padding-bottom: 1rem;
        /* margin-bottom: 1.25rem; */
        width: 100%;

        /* &::after {
          width: 50px;
          height: 4px;
        } */
      }
      
      @media ${MEDIA.XS} {
        font-size: 28px;
      }
    }

    h2 {
      font-size: 2.5rem;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      font-family: ${MEDIUM_FONT};

      @media ${MEDIA.MEDIUM} {
        font-size: 2.25rem;
        padding-bottom: 1rem;
      }

      @media ${MEDIA.SMALL} {
        font-size: 1.8125rem;
        padding-top: 1rem;
        padding-bottom: 0.9375rem;
      }
    }
    
    h3 {
      font-size: 1.75rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      font-family: ${BOOK_FONT};

      @media ${MEDIA.MEDIUM} {
        font-size: 1.6875rem;
        padding-bottom: 1rem;
      }

      @media ${MEDIA.SMALL} {
        font-size: 1.4375rem;
        padding-top: 1rem;
        padding-bottom: 0.9375rem;
      }
    }

    a {
      color: ${DARK_GRAY};
      text-decoration: none;
      font-weight: normal;
      position: relative;
      cursor: pointer;

      background-image: linear-gradient(to bottom,hsl(171,55%,65%) 0%, hsl(171,55%,65%) 100%);
      background-repeat: repeat-x;
      background-position: 50% 102%;
      background-size: 100% 3px;
      padding-bottom: 1px;
      transition: all .2s ease-out;

      &:hover {
        text-decoration: none;
        color: ${DARK_GRAY};
        background-image: linear-gradient(to bottom,${ACCENT_COLOR_LIGHTER} 0%, ${ACCENT_COLOR_LIGHTER} 100%);
      }
    }

    ul, ol {
      padding-left: 2rem;

      @media ${MEDIA.MEDIUM} {
        padding-left: 1.5rem;
      }
      
      @media ${MEDIA.MEDIUM} {
        padding-left: 1rem;
      }
      
      @media ${MEDIA.SMALL} {
        padding-left: 0.5625rem;
      }

      li {
        padding-bottom: 0.5625rem;

        &::before {
          content: "● ";
          font-size: 13px;
          /* color: ${LIGHTER_GRAY}; */
          display: inline-block;
          margin-right: 15px;
        }

        @media ${MEDIA.MEDIUM} {
          padding-left: 1rem;
        }

        @media ${MEDIA.SMALL} {
          line-height: 1.5;

          &::before {
            font-size: 9px;
            margin-right: 12px;
          }
        }
      }
    }

    iframe {
      margin: 1.25rem 0 1.5rem;

      @media ${MEDIA.SMALL} {
        margin-top: 1rem;
        margin-border-radius: 1.25rem;
      }
    }
    
  }

  .donations-wrapper {
    width: 100%;
    padding: 35px 0;
    border: 1px solid hsl(300,1%,76%);
    border-radius: 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 10px auto 30px auto;
    position: relative;

    @media ${MEDIA.SMALL} {
      padding: 30px 0;
      flex-direction: column;
    }

     /* a {
      background: none;
      display: block;
      width: 60px;
      height: 60px;
      background-color: ${LIGHT};
      color: ${DARK_GRAY};
      border-radius: 50%;
      margin-right: 20px;
      font-size: 18px;
      font-family: ${DEMI_FONT};
      line-height: 64px;
      text-align: center;
      padding: 0;
      transition: all .15s ease-out;

      &:hover {
        background-color: ${ACCENT_COLOR};
        color: ${DARK_GRAY};
      }
    } */
    
    a {
      display: block;
      text-align: center;
      padding: 0;
      transition: all .15s ease-out;
      
      &.paypal {
        width: 160px;
        height: 45px;
        border-radius: 4px;
        padding: 0;
        background: ${LIGHT} url(${LogoPayPal}) 50% 50% no-repeat;
        background-size: auto 80%;

        @media ${MEDIA.SMALL} {
          margin-bottom: 30px;
          height: 32px;
          width: 130px;
        }
      }

      &:hover {
        background-color: ${LIGHT};
      }
    }
    
    .bitcoin-wrapper {
      text-align: center;

      p {
        @media ${MEDIA.SMALL} {
          font-size: 0.875rem;
          padding-bottom: 1rem;
        }
      }
    }

    .bitcoin-img {
      background: ${LIGHT};
      border-radius: 4px;
      padding: 5px;
      display: block;
      margin: 0 auto;
      width: 120px;

      @media ${MEDIA.SMALL} {
        width: 100px;
      }

    }
  }

  p code {
    font-family: monospace,monospace;
    font-size: 1rem;
    padding: 2px 6px 3px;
    color: ${DARK_GRAY};
    background: hsl(300,1%,88%);
    font-weight: normal;
    border-radius: 3px;
    margin-right: 1px;

    @media ${MEDIA.SMALL} {
      font-size: 0.9375rem;
      padding: 1px 5px 2px;
    }
  }

  .chrome-picker {
    border-radius: 3px !important;
    overflow: hidden;
    font-family: ${DEMI_FONT} !important;
    width: 212px !important;

    input {
      font-size: 12px !important;
      color: ${DARK_GRAY} !important;
      border-radius: ${INPUT_BORDER} !important;
      height: 22px !important;
      padding: 3px 0 0 0;
    }
  }
`
export default GlobalStyles
