import React from 'react'

const IconCode = () => (

  <svg className="iconCode" viewBox="0 0 87 63" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="nonzero" stroke="none" strokeWidth="1">
      <path d="M53.8062736.88376336L50.9624801.10564122c-.3660344-.12183206-.726608-.08351908-1.0772237.11461832-.35174.19829771-.5892849.49598474-.7113499.89258015L32.0697076 60.1994656c-.122065.3967558-.0838394.7709084.1148375 1.1213359.1986769.3510687.4962906.5875191.8942868.7093512l2.8429904.7784427c.3668375.1227939.7261262.0843206 1.0777056-.1141374.35174-.1992595.5888031-.4959847.7107074-.8919389L54.814916 2.7149313c.1219044-.39659542.084-.77074809-.1149981-1.1219771-.1988374-.3510687-.49613-.58751909-.8936443-.70919084z" />
      <path d="M27 12.3618151c0-.3907248-.1548502-.7367547-.4640633-1.0371422l-2.3198288-2.25464319c-.309213-.30054539-.6650599-.45121292-1.067053-.45121292-.4019932 0-.7580025.15082546-1.0670531.45121292L.46406326 30.0819905C.15436278 30.3825359 0 30.7284079 0 31.1191327c0 .3907248.15485024.7365968.46406326 1.0369842L22.0818394 53.1680777c.3090505.3010192.6644099.4507391 1.067053.4507391.4026431 0 .7580025-.1501937 1.067053-.4507391l2.3198289-2.2533798C26.8449873 50.6143105 27 50.2682805 27 49.8770819c0-.390251-.1550127-.736123-.4642257-1.0365104L8.30423008 31.1191327 26.5359367 13.3989573C26.8456372 13.0985699 27 12.7525399 27 12.3618151z" />
      <path d="M86.536231 29.4629477L64.9163738 8.45120817C64.6073028 8.15082387 64.2512699 8 63.8499001 8c-.4026698 0-.7574027.15082387-1.0676112.45120817l-2.3190074 2.25446153C60.1542105 11.0062119 60 11.3516065 60 11.742801s.154048.736747.4632815 1.0371313l18.2332386 17.7204625L60.4632815 48.221805C60.1542105 48.5221893 60 48.8682156 60 49.2583045c0 .3913524.154048.7372208.4632815 1.0376051l2.3190074 2.253356c.3102085.301016.6651039.4507344 1.0676112.4507344.4015323 0 .7574027-.1501921 1.0664737-.4507344L86.536231 31.537684C86.8454645 31.2372997 87 30.8907997 87 30.500079c0-.3908787-.1545355-.736747-.463769-1.0371313z" />
    </g>
  </svg>
)

export default IconCode
