import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format' // https://github.com/s-yadav/react-number-format#readme

// Components
import { Input, Picker } from '..'
import { ToggleSmall } from '../Toggle'

// Styled Components
import {
  SidebarFieldset, FieldsetHeader, FieldsetContent, FieldsetTitle,
} from '../../styles/layout'

// Utils
import { getArrowBasedValue } from '../../utils'

const numberInputProps = {
  as: NumberFormat,
  suffix: 's',
}

const FillPanel = ({
  disabled,
  easings,
  shouldAnimateFill,
  hasFills,
  // fill,
  fillDuration,
  fillStaggerStep,
  fillDelay,
  fillEasing,
  onChangeAnimateFill,
  // onChangeFill,
  onChangeFillDuration,
  onChangeFillStaggerStep,
  onChangeFillDelay,
  onChangeFillEasing,
}) => (
  <SidebarFieldset>
    <FieldsetHeader disabled={!hasFills || disabled}>
      <FieldsetTitle>Fill Animation</FieldsetTitle>
      <ToggleSmall
        id="fill-switch"
        disabled={!hasFills || disabled}
        onChange={e => onChangeAnimateFill(e.target.checked)}
        checked={hasFills && shouldAnimateFill}
      />
    </FieldsetHeader>

    <FieldsetContent disabled={!hasFills || !shouldAnimateFill}>
      {/* <Input label="Color" value={fill} disabled={disabled} onChange={e => onChangeFill(e.target.value)} /> */}
      <Input
        {...numberInputProps}
        label="animation duration:"
        step="0.05"
        value={fillDuration}
        disabled={!hasFills || !shouldAnimateFill || disabled}
        onChange={e => onChangeFillDuration(parseFloat(e.target.value))}
        onKeyDown={e => onChangeFillDuration(getArrowBasedValue(e))}
      />
      <Input
        {...numberInputProps}
        label="stagger step:"
        step="0.05"
        value={fillStaggerStep}
        disabled={!hasFills || !shouldAnimateFill || disabled}
        onChange={e => onChangeFillStaggerStep(parseFloat(e.target.value))}
        onKeyDown={e => onChangeFillStaggerStep(getArrowBasedValue(e))}
      />
      <Input
        {...numberInputProps}
        label="animation delay:"
        step="0.05"
        value={fillDelay}
        disabled={!hasFills || !shouldAnimateFill || disabled}
        onChange={e => onChangeFillDelay(parseFloat(e.target.value))}
        onKeyDown={e => onChangeFillDelay(getArrowBasedValue(e))}
      />
      <Picker
        label="animation easing:"
        items={easings}
        value={fillEasing}
        onChange={e => onChangeFillEasing(e.target.value)}
        disabled={!hasFills || !shouldAnimateFill || disabled}
      />
    </FieldsetContent>
  </SidebarFieldset>
)

FillPanel.propTypes = {
  disabled: PropTypes.bool,
  easings: PropTypes.arrayOf(PropTypes.object),
  hasFills: PropTypes.bool,
  shouldAnimateFill: PropTypes.bool,
  fillEasing: PropTypes.string,
  // fill: PropTypes.string, // color
  fillDuration: PropTypes.number,
  fillStaggerStep: PropTypes.number,
  fillDelay: PropTypes.number,
  onChangeAnimateFill: PropTypes.func,
  // onChangeFill: PropTypes.func,
  onChangeFillDuration: PropTypes.func,
  onChangeFillStaggerStep: PropTypes.func,
  onChangeFillDelay: PropTypes.func,
  onChangeFillEasing: PropTypes.func,
}

export default FillPanel
