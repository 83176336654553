import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format' // https://github.com/s-yadav/react-number-format#readme

// Components
import { Input, Picker } from '..'
import { ToggleSmall } from '../Toggle'

// Styled Components
import {
  SidebarFieldset, FieldsetHeader, FieldsetContent, FieldsetTitle,
} from '../../styles/layout'

// Utils
import { getArrowBasedValue } from '../../utils'

const numberInputProps = {
  as: NumberFormat,
  suffix: 's',
}

const StrokePanel = ({
  disabled,
  easings,
  direction,
  hasStrokes,
  shouldAnimateStroke,
  // stroke,
  // strokeWidth,
  strokeDuration,
  strokeStaggerStep,
  strokeDelay,
  strokeEasing,
  strokeDirection,
  onChangeAnimateStroke,
  // onChangeStroke,
  // onChangeStrokeWidth,
  onChangeStrokeDuration,
  onChangeStrokeStaggerStep,
  onChangeStrokeDelay,
  onChangeStrokeEasing,
  onChangeStrokeDirection,
}) => (
  <SidebarFieldset>
    <FieldsetHeader disabled={!hasStrokes || disabled}>
      <FieldsetTitle>Stroke Animation</FieldsetTitle>
      <ToggleSmall
        id="stroke-switch"
        disabled={!hasStrokes || disabled}
        onChange={e => onChangeAnimateStroke(e.target.checked)}
        checked={hasStrokes && shouldAnimateStroke}
      />
    </FieldsetHeader>

    <FieldsetContent disabled={!hasStrokes || !shouldAnimateStroke}>
      {/* <ToggleSmall disabled={disabled} onChange={e => onChangeAnimateStroke(e.target.checked)} checked={shouldAnimateStroke} /> */}
      {/* <Input label="Color" value={stroke} disabled={disabled} onChange={e => onChangeStroke(e.target.value)} /> */}
      {/* <Input label="Width" value={strokeWidth} disabled={disabled} onChange={e => onChangeStrokeWidth(e.target.value)} /> */}
      <Input
        {...numberInputProps}
        label="animation duration:"
        value={strokeDuration}
        disabled={!hasStrokes || !shouldAnimateStroke || disabled}
        onChange={e => onChangeStrokeDuration(parseFloat(e.target.value))}
        onKeyDown={e => onChangeStrokeDuration(getArrowBasedValue(e))}
      />
      <Input
        {...numberInputProps}
        label="stagger step:"
        value={strokeStaggerStep}
        disabled={!hasStrokes || !shouldAnimateStroke || disabled}
        onChange={e => onChangeStrokeStaggerStep(parseFloat(e.target.value))}
        onKeyDown={e => onChangeStrokeStaggerStep(getArrowBasedValue(e))}
      />
      <Input
        {...numberInputProps}
        label="animation delay:"
        value={strokeDelay}
        disabled={!hasStrokes || !shouldAnimateStroke || disabled}
        onChange={e => onChangeStrokeDelay(parseFloat(e.target.value))}
        onKeyDown={e => onChangeStrokeDelay(getArrowBasedValue(e))}
      />
      <Picker
        label="animation easing:"
        items={easings}
        value={strokeEasing}
        onChange={e => onChangeStrokeEasing(e.target.value)}
        disabled={!hasStrokes || !shouldAnimateStroke || disabled}
      />
      <Picker
        label="animation direction:"
        items={direction}
        value={strokeDirection}
        onChange={e => onChangeStrokeDirection(e.target.value)}
        disabled={!hasStrokes || !shouldAnimateStroke || disabled}
      />
    </FieldsetContent>
  </SidebarFieldset>
)

StrokePanel.propTypes = {
  hasStrokes: PropTypes.bool,
  shouldAnimateStroke: PropTypes.bool,
  strokeEasing: PropTypes.string,
  // stroke: PropTypes.string, // color
  strokeDirection: PropTypes.string,
  // strokeWidth: PropTypes.string, // in pixels
  strokeDuration: PropTypes.number,
  strokeStaggerStep: PropTypes.number,
  strokeDelay: PropTypes.number,
  easings: PropTypes.arrayOf(PropTypes.object),
  direction: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  onChangeAnimateStroke: PropTypes.func,
  // onChangeStroke: PropTypes.func,
  // onChangeStrokeWidth: PropTypes.func,
  onChangeStrokeDuration: PropTypes.func,
  onChangeStrokeStaggerStep: PropTypes.func,
  onChangeStrokeDelay: PropTypes.func,
  onChangeStrokeEasing: PropTypes.func,
  onChangeStrokeDirection: PropTypes.func,
}

export default StrokePanel
