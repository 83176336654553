import React, { useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash/throttle'
import styled from 'styled-components'

// Constants
import { ADS_REFRESH_INTERVAL, PAD_LR, MEDIA } from '../../constants'

// import './styles.css'

const CarbonWrapper = styled.div`
  width: 270px;
  height: 100px;
  /* position: absolute; */
  position: relative;
  /* right: ${PAD_LR}; */
  /* left: ${PAD_LR / 2}; */
  /* bottom: ${PAD_LR}; */
  /* bottom: 15px; */
  bottom: 0;
  margin: 20px auto;

  font-family: FuturaBook, "Helvetica Neue", Helvetica, Arial, sans-serif;
  /* font-family: 'HelveticaNeue-Light','Roboto-Thin',sans-serif-light, sans-serif; */
  /* display: flex; */
  /* max-width: 330px; */
  /* background-color: hsl(0, 0%, 98%); */
  /* box-shadow: 0 1px 6px hsla(0, 0%, 0%, .1); */
  /* background: hsl(300,1%,95%); */
  background: hsl(300,1%,100%);
  /* position: absolute; */
  /* right: ${PAD_LR}; */
  /* right: 0; */
  /* bottom: 0; */
  /* left: 0; */
  /* top: 0; */

  a {
    color: inherit;
    text-decoration: none;
    color: hsl(300,2%,25%);

    &:hover {
      color: inherit;
      color: hsl(171, 100%, 36%);
    }
  }

  span {
    /* position: relative;
    display: block;*/
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .carbon-img {
    display: block;
    margin: 0;
    line-height: 1;
  }

  .carbon-img img {
    display: block;
  }

  .carbon-text {
    font-size: 14px;
    /* padding: 10px; */
    line-height: 1.05;
    text-align: left;
    /* color: hsl(300,2%,10%); */

    position: absolute;
    left: 130px;
    top: 0;
    padding: 10px 10px 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 24px;
  }

  a.carbon-poweredby {
    display: inline-block;
    padding: 0 10px 8px 10px;
    /* background: repeating-linear-gradient(-45deg, transparent, transparent 5px, hsla(0, 0%, 0%, .025) 5px, hsla(0, 0%, 0%, .025) 10px) hsla(203, 11%, 95%, .4); */
    text-align: right;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 300;
    font-size: 10px;
    line-height: 1;
    color: hsl(300,1%,45%) !important;

    position: absolute;
    left: 130px;
    right: 0;
    bottom: 0;
  }

  @media ${MEDIA.MEDIUM} {
    width: 130px;
    height: 220px;
    
    a.carbon-img {
      display: block;
    }

    a.carbon-text {
      display: block;
      position: relative;
      left: auto;
    }

    a.carbon-poweredby {
      display: block;
      left: 0;
      /* position: relative; */
    }
  }
`

const CarbonAds = ({
  customClass, serve, placement, callback,
}) => {
  const carbonWrapper = useRef(null)
  const timerID = useRef(null)
  // const [isActive, setIsActive] = useState(false)

  const classes = ['carbon-ads-wrapper', customClass].filter(c => c).join(' ')

  const refreshAds = () => {
    // console.log('refreshing...')
    // console.log(carbonAd.current)
    // eslint-disable-next-line no-underscore-dangle
    if (window._carbonads) {
      // eslint-disable-next-line no-underscore-dangle
      window._carbonads.refresh() // Should use useRef for this
      // window._carbonads.refresh(document.getElementById('carbon_refresh')) // Should use useRef for this
    }
  }

  const handleClick = useCallback(
    throttle(refreshAds, ADS_REFRESH_INTERVAL, {
      trailing: false,
      leading: true,
    }),
    [],
  )

  const initCarbon = useCallback(() => {
    // Don't initialise Carbon if in react-snap or node environments (e.g. Gatsby)
    const shouldInitCarbon = typeof window !== 'undefined' && navigator.userAgent !== 'ReactSnap' && (!carbonWrapper.current.hasChildNodes() || !carbonWrapper.current.children.length)

    if (shouldInitCarbon) {
      // console.log('adding carbon')
      // console.log(navigator.userAgent)
      const script = document.createElement('script')
      script.src = `https://cdn.carbonads.com/carbon.js?serve=${serve}&placement=${placement}`
      script.async = true
      script.id = '_carbonads_js'

      if (typeof callback === 'function') {
        script.onload = callback()
      }

      // while (carbonWrapper.current.firstChild) {
      //   carbonWrapper.current.removeChild(carbonWrapper.current.firstChild)
      // }
      carbonWrapper.current.appendChild(script)
    }
  }, [serve, placement, callback])

  useEffect(() => {
    initCarbon()
    const cWrapper = carbonWrapper.current

    timerID.current = setTimeout(() => document.addEventListener('click', handleClick), ADS_REFRESH_INTERVAL)

    return () => {
      clearTimeout(timerID.current)
      document.removeEventListener('click', handleClick)

      while (cWrapper.firstChild) {
        cWrapper.removeChild(cWrapper.firstChild)
      }
    }
  }, [handleClick, initCarbon])

  return <CarbonWrapper className={classes} ref={carbonWrapper} />
}

CarbonAds.propTypes = {
  serve: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  callback: PropTypes.func,
}

export default CarbonAds
