import styled, { css } from 'styled-components'
// import { gray } from 'ansi-colors'
import { Link } from '@reach/router'

// import IconPencile from '../images/iconPencil.svg'
import LogoTextW from '../images/logo-text.svg'
// import LogoIconW from '../images/logo-icon.svg'

import {
  // LIGHT_FONT,
  HEADER_H,
  HEADER_H_S,
  LOGO_W,
  BOOK_FONT,
  MEDIUM_FONT,
  // DEMI_FONT,
  ACCENT_COLOR,
  // ACCENT_COLOR_DARKER,
  DARK_GRAY,
  GRAY,
  LIGHT_GRAY,
  LIGHTER_GRAY,
  LIGHT,
  PAD_LR,
  PAD_LR_S,
  PAD_T,
  PAD_B,
  PAD_T_S,
  PAD_B_S,
  BORDER_RAD,
  TOOLBAR_R,
  TOOLBAR_R_S,
  SIDEBAR_W,
  SIDEBAR_W_M,
  STAGE_W,
  STAGE_W_M,
  // SIDEBAR_PAD_R,
  SIDEBAR_ITEM_PAD,
  INPUT_H,
  INPUT_W,
  INPUT_BORDER,
  INPUT_MARGIN_B,
  BTN_SMALL_H,
  BTN_SMALL_H_S,
  BTN_SMALL_W,
  BTN_SMALL_W_S,
  EASE,
  MAIN_CONTENT_H,
  // DARKER_GRAY,
  SHADOW,
  CODE_H,
  LIGHT_FONT,
  FADED,
  MEDIA,
} from '../constants'

// import imgCarbonAd from '../images/carbon-ad.png'

import ArrowDdlLight from '../images/arrow-ddl-light.svg'
import ArrowDdlDark from '../images/arrow-ddl-dark.svg'
// import IconPlay from '../images/icon-play.svg'
// import IconCode from '../images/icon-code.svg'

const MainWrapper = styled.div`
  position: relative;
  /* margin: 0 ${PAD_LR}; */
  margin: 0;
`

const AppWrapper = styled.div`
  position: absolute;
  margin: 0;
  top: ${HEADER_H};
  left: 0;
  width: 100%;
  /* height: auto; */
  /* height: 100vh; */
  /* height: calc(100vh - ${HEADER_H}); */
  height: ${props => (props.height ? `calc(${props.height} - ${HEADER_H})` : `calc(100vh - ${HEADER_H})`)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  overflow: hidden;

  @media ${MEDIA.SMALL} {
    top: ${HEADER_H_S};
    /* height: calc(100vh - ${HEADER_H_S}); */
    height: ${props => (props.height ? `calc(${props.height} - ${HEADER_H_S})` : `calc(100vh - ${HEADER_H_S})`)};
  }
`

// HEADER
const StyledHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 ${PAD_LR};
  margin: 0px;
  height: ${HEADER_H};
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: ${SHADOW};
  z-index: 2;
  background-color: ${GRAY};

  @media ${MEDIA.SMALL} {
    padding: 0 ${PAD_LR_S};
    height: ${HEADER_H_S};
  }
`

const LogoWrapper = styled.div`
  width: ${LOGO_W};
  height: ${BTN_SMALL_H};
  overflow: hidden;

  @media ${MEDIA.SMALL} {
    width: ${BTN_SMALL_W_S};
    height: ${BTN_SMALL_H_S};
  }
`

const Logo = styled(Link)`
  display: block;
  font-size: 0px;
  color: transparent;
  width: ${LOGO_W};
  height: ${BTN_SMALL_H};
  background: transparent url(${LogoTextW}) 0% 60% no-repeat;
  background-size: contain;
  opacity: 0.85;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  @media ${MEDIA.SMALL} {
    height: ${BTN_SMALL_H_S};
    width: 130px;
  }
`

const AppContent = styled.div`
  position: relative;
  /* display: flex;
  justify-content: flex-start; */
  overflow: hidden;
  /* height: ${MAIN_CONTENT_H}; */
  height: 100%;
`

// const fildsetHide = css`
//   transform: translateY(120%);
//   transition: transform .4s ${EASE.easeOutQuad} 0s;
// `
// const fildsetShow = css`
//   transform: none;
//   transition: transform .5s ${EASE.easeOutQuad} .1s;
// `

const sidebarShown = css`
  transform: translateX(0);
`

const sidebarHidden = css`
  transform: translateX(-100%);
`

const SidebarWrapper = styled.div`
  position: relative;
  width: ${SIDEBAR_W};
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: ${GRAY};
  padding: 5px ${SIDEBAR_ITEM_PAD} 0 ${PAD_LR};
  z-index: 1;

  @media ${MEDIA.NOT_LARGE} {
    padding: 5px ${SIDEBAR_ITEM_PAD};
    width: calc(100% - ${TOOLBAR_R_S});
  }

  @media ${MEDIA.MEDIUM} {
    width: ${SIDEBAR_W_M};
  }

  @media ${MEDIA.SMALL} {
    ${props => (props.shown ? sidebarShown : sidebarHidden)}
    transition: transform .3s cubic-bezier(0.250,0.460,0.450,0.940);
  }

`

const svgHide = css`
  transform: translateY(120%);
  transition: transform 0.4s ${EASE.easeOutQuad} 0s;
`
const svgShow = css`
  transform: translateY(0);
  transition: transform 0.45s ${EASE.easeOutQuad} 0s;
`

const StageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: ${STAGE_W};
  overflow: hidden;
  /* background-color: ${DARK_GRAY}; */
  background-color: ${LIGHT};

  /* ${props => (props.codeShown ? svgHide : svgShow)} */

  @media ${MEDIA.MEDIUM} {
    width: ${STAGE_W_M};
  }

  @media ${MEDIA.SMALL} {
    width: 100%;
  }

`

const SvgWrapper = styled.div`
  /* background: ${props => props.bgColor || '#fff'}; */
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  position: absolute;
  /* top: calc(${BTN_SMALL_H} + 15px); */
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: ${props => (props.shown ? 1 : 0)};
  /* border-radius: ${BORDER_RAD}; */

  @media ${MEDIA.SMALL} {
    padding-bottom: 100px;
  }
  
  background: ${props => props.bgColor || `${DARK_GRAY}`};

  svg {
    max-width: 70%;
    max-height: 70%;

    @media ${MEDIA.SMALL} {
      max-width: 80%;
      max-height: 70%;
    }

  }
`

// TOOLBAR
const toolbarHide = css`
  transform: translateY(-180%);
  transition: transform 0.3s ${EASE.easeOutQuad} 0.1s;

  /* &:first-child {
    transition-delay: .1s;
  }

  &:nth-child(2) {
    transition-delay: .05s;
  }

  &:nth-child(3) {
    transition-delay: 0s;
  }  */
`

const toolbarShow = css`
  transform: translateY(0);
  transition: transform 0.3s ${EASE.easeOutQuad} 0.15s;

  /* &:first-child {
    transition-delay: 0s;
  }

  &:nth-child(2) {
    transition-delay: .05s;
  }

  &:nth-child(3) {
    transition-delay: .1s;
  }  */
`

const ToolbarWrapper = styled.div`
  height: ${BTN_SMALL_H};
  /* background: red; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute; 
  /* left: 0;*/
  position: fixed;
  top: 11px;
  /* right: calc(${BTN_SMALL_W} + ${PAD_LR} + 8px); */
  right: ${TOOLBAR_R};
  z-index: 2;

  @media ${MEDIA.SMALL} {
    height: ${BTN_SMALL_H_S};
    top: 10px;
    /* right: calc(${BTN_SMALL_W_S} + ${PAD_LR_S} + 5px); */
    right: ${TOOLBAR_R_S};
  }
`

const toolbarOn = css`
  opacity: 1;
  pointer-events: all;
`
const toolbarOff = css`
  opacity: ${FADED};
  pointer-events: none;
`

const ToolbarButtons = styled.div`
  display: flex;
  ${props => (props.disabled ? toolbarOff : toolbarOn)};
  /* ${props => (props.codeShown ? toolbarHide : toolbarShow)}; */
  transition: opacity .25s ease-out;
  
  ${props => (props.codeShown ? toolbarHide : toolbarShow)};
  /* will-change: transform, opacity; */

  /*div,
  button {
     will-change: transform;
    ${props => (props.codeShown ? toolbarHide : toolbarShow)}; 
  }*/
`

const btnIcon = css`
  height: ${BTN_SMALL_H};
  border: 1px solid ${LIGHTER_GRAY};
  border-radius: ${BORDER_RAD};
  background-color: transparent;
  outline: none;
  cursor: pointer;
  padding: 0;
  font-size: 0px;
  color: transparent;
  text-indent: -10000px;
  transition: all .15s ease-out;

  svg {
    width: auto;
    height: 12px;
    /* transform: scale(.8); */
    /* transition: all .18s ease-out; */

    path {
      fill: ${LIGHT};
      stroke: ${LIGHT};
    }
  }

  @media ${MEDIA.SMALL} {
    height: ${BTN_SMALL_H_S};

    svg {
      height: 10px;
    }

  }

  &:hover {
    border-color: ${ACCENT_COLOR};
    background-color: ${ACCENT_COLOR}; 
    transition: all .2s ease-out;
     /* color: ${DARK_GRAY}; */

    /*svg {
       transform: scale(1); 
      
      path {
        fill: ${DARK_GRAY};
      }
    }*/

    /* .iconClose,
    .iconPlay {
      g {
        stroke: ${DARK_GRAY};
      }
    } */
  }
`

const btnBig = css`
  height: 37px;

  @media ${MEDIA.NOT_LARGE} {
    height: 36px;
  }

  @media ${MEDIA.SMALL} {
    height: 34px;
  }
`

const btnToolbar = css`
  height: ${BTN_SMALL_H};
  /* font-family: ${MEDIUM_FONT}; */
  margin-left: 8px;
  padding: 0 10px;
  font-size: 11px;
  letter-spacing: 1px;

  svg {
    margin-right: 6px;
    height: 11px;
  }

  span {
    padding-top: 2px;
  }

  @media ${MEDIA.SMALL} {
    width: ${BTN_SMALL_W_S};

    svg {
      position: absolute;
      margin-right: 0;
      height: 10px;
    }

    span {
      display: none;
    }
  }
`

const btnSizeProps = css`
  ${props => ({
    toolbar: btnToolbar,
    big: btnBig,
  }[props.kind])};
`

const btnIconText = css`
  ${btnIcon}
  display: flex;
  justify-content: center;
  align-items: center;
  /*font-family: ${MEDIUM_FONT};*/
  font-family: ${MEDIUM_FONT};
  font-size: 11px;
  line-height: 1;
  text-transform: uppercase;
  color: ${LIGHT};
  text-align: left;
  letter-spacing: 1px;
  padding: 0 18px;
  text-indent: 0;

  svg {
    margin-right: 8px;
  }
  
  span {
    display: inline-block;
    line-height: 1;
    padding-top: 2px;
  }
  
  ${btnSizeProps}
`

const BtnInputLabel = styled.label`
  ${btnIconText}
`

const BtnInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  outline: none;
  overflow: hidden;
`

const BtnStyledLink = styled.button`
  ${btnIconText}
`

const BtnApply = styled.button`
  ${btnIconText}
  margin-bottom: 20px;
  margin-top: -5px;
  display: none;

  @media ${MEDIA.SMALL} {
    display: block;
    line-height: 35px;
    padding: 0 20px;
  }
`

const BtnSmall = styled.button`
  ${btnIcon}
  width: ${BTN_SMALL_W};
  margin: 0 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-child {
    margin-left: 0px;
  }

  svg {
    transform-origin: 50% 50%;
  }

  @media ${MEDIA.SMALL} {
    width: ${BTN_SMALL_W_S};
    margin-left: 8px;
  }

`

const SidebarButtons = styled.div`
  /* padding-bottom: 16px; */
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  @media ${MEDIA.NOT_LARGE} {
    flex-direction: column;

    ${BtnInputLabel},
    ${BtnStyledLink} {
      justify-content: center;

      svg {
        margin-right: 12px;
      }
    }

    ${BtnInputLabel} {
      margin-bottom: 8px;
    }
  }
`

const codeHide = css`
  transform: translateY(0);
  transition: transform 0.4s ${EASE.easeOutQuad} 0.1s;
`

const codeShow = css`
  transform: translateY(110%);
  transition: transform 0.4s ${EASE.easeInQuad} 0s;
`

// Generated Code
const CodeWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* height: 100%;
  width: 100%; */
  overflow: hidden;
  pointer-events: none;
  z-index: 3;
`

const CodeContent = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* width: 100%;
  height: 100%; */
  padding: ${SIDEBAR_ITEM_PAD};
  background-color: ${DARK_GRAY}; 
  pointer-events: all;
  ${props => (props.codeShown ? codeHide : codeShow)}

  @media ${MEDIA.SMALL} {
    flex-direction: column;
    padding: ${PAD_LR_S};
  }
`

const codeHeader = css`
  display: inline-block;
  height: 38px;
  line-height: 39px;
  width: max-content;
  width: fit-content;
  font-family: ${BOOK_FONT};
  font-size: 12px;
  padding: 0 20px 0 20px;
  margin: 8px 0 0 0;
  background-color: ${GRAY};
  color: ${LIGHT};
  text-transform: uppercase;
  letter-spacing: 2px;
  border-top-left-radius: ${BORDER_RAD};
  border-top-right-radius: ${BORDER_RAD};

  @media ${MEDIA.SMALL} {
    height: 30px;
    line-height: 32px;
    margin: 0;
    font-size: 11px;
    padding: 0 18px;
  }
`

const CodeHeader = styled.h4`
  ${codeHeader}
`

const generatedCode = css`
  margin-top: 0;
  width: 49%;
  height: ${CODE_H};
  position: relative;
  padding: 0;
  display: block;
  /* flex-direction: column;
  align-items: flex-start; */

  @media ${MEDIA.SMALL} {
    width: 100%;
    height: 46%;
  }

  h4 {
    ${codeHeader}
  }

  div {
    width: 100%;
    display: block;
    /* height: calc(100% - 42px); */
    height: 85%;
    margin: 0;
    padding: 10px 5px 5px;
    /* top: 40px; */
    border: 1px solid ${GRAY};
    border-bottom-left-radius: ${BORDER_RAD};
    border-bottom-right-radius: ${BORDER_RAD};
    border-top-right-radius: ${BORDER_RAD};

    -webkit-overflow-scrolling: touch;

    @media ${MEDIA.SMALL} {
      width: 100%;
      height: calc(100% - 76px);
    }
  }

  pre[class*='language-'] {
    border: none;
    padding: 15px;
    border-radius: 0;
    /* padding: 0; */
    margin: 0;
    height: 100%;
    background: transparent;

    @media ${MEDIA.SMALL} {
      padding: 7px;
    }

    code {
      font-size: 13px;
      line-height: 1.35;
      -webkit-overflow-scrolling: touch;

      @media ${MEDIA.SMALL} {
        font-size: 11px;
        line-height: 1.2;
      }
    }
  }

  .token.comment {
    color: ${LIGHT_GRAY};
  }
`
const GeneratedCode = styled.div`
  ${generatedCode}
`

const GeneratedCodeAnim = styled.div`
  ${generatedCode}

  div {
    padding-top: 45px;
  }

  @media ${MEDIA.SMALL} {
    margin-bottom: 10px;

    div {
      padding-top: 38px;
    }
  }
`

const CheckMark = styled.span`
  display: block;
  width: 14px;
  height: 14px;
  margin-right: 8px;
  border-radius: ${BORDER_RAD};
  /* background-color: ${ACCENT_COLOR}; */
  border: 1px solid ${ACCENT_COLOR};
  position: relative;

  &::after {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
  }

  @media ${MEDIA.SMALL} {
    margin-right: 7px;
  }
`

const CheckboxWrapper = styled.span`
  display: block;
  padding: 0;
  margin: 0;
  user-select: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  left: 20px;
  right: 20px;
  width: auto;
  height: 42px;
  top: 50px;

  &::after {
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${GRAY};
  }

  @media ${MEDIA.SMALL} {
    top: 34px;
    height: 34px;
    left: 12px;
    right: 12px;
  }
`

const LabelCheck = styled.label`
  user-select: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: 100%;
  padding-right: 15px;
  margin: 0;
  cursor: pointer;
  font-family: ${LIGHT_FONT};
  color: ${LIGHT};
  font-size: 15px;
  letter-spacing: 2px;

  @media ${MEDIA.SMALL} {
    font-size: 14px;
    padding-right: 12px;
  }

  span {
    display: block;
    height: 14px;
    line-height: 1;
    padding-top: 1px;
  }

   input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  input[type=checkbox] + ${CheckMark}::after {
    background-color: ${ACCENT_COLOR};
    transform: scale(0.5);
    opacity: 0;
    transition: all .15s;
  }
  
  input[type=checkbox]:checked + ${CheckMark}::after {
    /* background-color: ${ACCENT_COLOR}; */
      /* background-color: ${ACCENT_COLOR}; */
      opacity: 1;
      transform: scale(1);
  }
`

const BtnWrapper = styled.span`
  display: block;
  margin-top: 20px;

  @media ${MEDIA.SMALL} {
    margin-top: 12px;
  }
`

// Btn Close Code
const closeHide = css`
  transform: translateY(0);
  transition: transform 0.3s ${EASE.easeOutQuad} 0.15s;
`

const closeShow = css`
  transform: translateY(180%);
  transition: transform 0.3s ${EASE.easeOutQuad} 0.1s;
`

const BtnCloseCodeWrapper = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  /* right: calc(${BTN_SMALL_W} + ${PAD_LR} + 10px); */
  right: ${TOOLBAR_R};
  height: ${HEADER_H};
  overflow: hidden;
  /* background: red; */
  display: flex;
  align-items: center;
  pointer-events: none;

  @media ${MEDIA.SMALL} {
    right: ${PAD_LR_S};
    height: ${HEADER_H_S};
    /* right: calc(${BTN_SMALL_W_S} + ${PAD_LR_S} + 8px); */
    right: ${TOOLBAR_R_S};
  }
`

const BtnCloseCode = styled.div`
  will-change: transform;
  pointer-events: all;

  ${props => (props.codeShown ? closeHide : closeShow)}
`

// Sidebar
const disabledOn = css`
  opacity: 1;
  pointer-events: all;
`

const disabledOff = css`
  opacity: ${FADED};
  pointer-events: none;
`

const FieldsetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  height: 22px;

  ${props => (props.disabled ? disabledOff : disabledOn)}
`

const fieldsetOn = css`
  opacity: 1;
  pointer-events: all;
`

const fieldsetOff = css`
  opacity: ${FADED};
  pointer-events: none;
`

const FieldsetContent = styled.div`
  transition: opacity 0.25s ease-out;
  ${props => (props.disabled ? fieldsetOff : fieldsetOn)}
`

const SidebarFieldset = styled.fieldset`
  border: none;
  color: hsl(300, 1%, 85%);
  letter-spacing: 1px;
  margin: 0;
  padding: ${SIDEBAR_ITEM_PAD} 0 calc(2px + ${SIDEBAR_ITEM_PAD}) 0;
  transition: opacity 0.35s ease-out;
`

const SidebarSeparator = styled.div`
  display: block;
  /* width: 100%; */
  height: 1px;
  background-color: hsla(300, 1%, 25%, 1);
  position: relative;
`

// const FieldsetHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 15px;
//   height: 22px;
// `

const FieldsetTitle = styled.h4`
  font: 14px/1 ${BOOK_FONT};
  text-transform: uppercase;
  letter-spacing: 2px;
  color: ${ACCENT_COLOR};
  margin: 0;

  @media ${MEDIA.NOT_LARGE} {
    font-size: 13px;
  }
`

const SidebarInputWrapper = styled.div`
  margin: 0 0 ${INPUT_MARGIN_B} 0;
  height: ${INPUT_H};
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &:last-child {
    margin-bottom: 0px;
  }

  @media ${MEDIA.NOT_LARGE} {
    height: auto;
    margin-bottom: 10px;
  }
`

const fieldCommonCSS = css`
  border-radius: ${INPUT_BORDER};
  border: none;
  height: 100%;
  width: ${INPUT_W};
  padding: 0 8px 2px;
  margin-left: 12px;
  font-family: ${BOOK_FONT};
  line-height: 1;
  font-size: 15px;
  letter-spacing: 1px;
  text-align: center;
  color: ${LIGHT};
  transition: all .15s ease-out;
  
  &:active,
  &:focus {
    background-color: ${ACCENT_COLOR};
    /*color: ${DARK_GRAY};*/
    outline: none;
  }
`

const SidebarInput = styled.input`
  ${fieldCommonCSS}
  background-color: ${LIGHT_GRAY};

  @media ${MEDIA.NOT_LARGE} {
    width: 100%;
    height: ${INPUT_H};
    margin-left: 0px;
    margin-top: 3px;
  }
`

const SidebarSelect = styled.select`
${fieldCommonCSS}
  vertical-align: middle;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  outline: 0 !important;
  box-sizing: border-box !important;
  border: 0;
  cursor: pointer;
  position: relative;
  background: ${LIGHT_GRAY} url(${ArrowDdlLight}) no-repeat;
  background-position: calc(100% - 9px) 50%;
  background-size: 9px;
  /* transition: all .2s ease-out; */

  /* &:active,
  &:focus {
    background-image: url(${ArrowDdlDark});
  } */

  @media ${MEDIA.NOT_LARGE} {
    width: 100%;
    height: ${INPUT_H};
    margin-left: 0px;
    margin-top: 3px;
  }
`

const SidebarLabel = styled.label`
  /* width: 55%; */
  width: 100%;
  height: 100%;
  text-align: right;
  padding: 0;
  /* padding: 0 10px 0 0; */
  font-size: 15px;
  line-height: 1;
  letter-spacing: 1px;
  /* background: green; */

  @media ${MEDIA.NOT_LARGE} {
    flex-direction: column;
    text-align: left;
  }
`

const ToggleWrapper = styled.div`
  width: 36px;
  height: 21px;
  position: relative;
`

const ToggleLabel = styled.label`
  cursor: pointer;
  text-indent: -9999px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  display: block;
  border: 1px solid ${LIGHTER_GRAY};
  border-radius: ${INPUT_BORDER};

  &::after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 13px;
    height: 13px;
    border-radius: ${INPUT_BORDER};
    transition: 0.3s;
  }
`

const togleOnCss = css`
  background: ${ACCENT_COLOR};
  transform: translateX(15px);
`

const togleOffCss = css`
  background: ${LIGHTER_GRAY};
  transform: translateX(0);
`

// eslint-disable-next-line no-unused-vars
const ToggleInput = styled.input.attrs(props => ({
  type: 'checkbox',
}))`
  height: 0;
  width: 0;
  visibility: hidden;

  + ${ToggleLabel}::after {
    ${props => (props.checked ? togleOnCss : togleOffCss)}
  }

  /* &:checked {
    &:active {
      + ${ToggleLabel}::after {
        width: 34px;
        transform: translateX(0px);
        transform-origin: right center;
      }
    }
  } */
`

const ToggleDrawWrapper = styled.div`
  position: relative;
  width: 82px;
  height: ${BTN_SMALL_H};

  @media ${MEDIA.SMALL} {
    height: ${BTN_SMALL_W_S};
    width: 75px;
  }

  ${ToggleLabel} {
    border-color: ${LIGHTER_GRAY};
    text-indent: 0px;
    overflow: hidden;
    border-radius: ${BORDER_RAD};

    &::after {
      /* height: calc(${BTN_SMALL_H} - 6px); */
      width: calc(${BTN_SMALL_H} - 10px);
      height: auto;
      top: 4px;
      left: 4px;
      bottom: 4px;

      @media ${MEDIA.SMALL} {
        width: calc(${BTN_SMALL_H_S} - 8px);
        top: 3px;
        left: 3px;
        bottom: 3px;
      }
    }

    &::before {
      /* content: 'DRAW'; */
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 4px;
      bottom: 4px;
      width: 48px;
      padding-top: 2px;
      font-family: ${MEDIUM_FONT};
      font-size: 11px;
      letter-spacing: 1px;
      line-height: 1;
      text-transform: uppercase;
      color: ${LIGHT};

      @media ${MEDIA.SMALL} {
        font-size: 11px;
        letter-spacing: 1px;
        top: 3px;
        bottom: 3px;
        width: 42px;
      }
    }

    /* &:hover {

      &::after {
        background: red;
      }
      
    } */
  }
`

const togleDrawOnCss = css`
  background-color: ${ACCENT_COLOR};
  transform: translateX(50px);

  @media ${MEDIA.SMALL} {
    transform: translateX(45px);
  }
`

const togleDrawOffCss = css`
  background: ${ACCENT_COLOR};
  transform: translateX(0);
`

const togleTextOnCss = css`
  content: 'DRAW';
  left: 4px;
  animation: fade-in-left 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0.05s both;

  @keyframes fade-in-left {
    0% {
      transform: translateX(50%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
`

const togleTextOffCss = css`
  content: 'ERASE';
  right: 4px;
  animation: fade-in-right 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0.05s both;

  @keyframes fade-in-right {
    0% {
      transform: translateX(-50%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
`

const ToggleDrawInput = styled.input.attrs(props => ({
  type: 'checkbox',
}))`
  height: 0;
  width: 0;
  visibility: hidden;

  /* + ${ToggleLabel}::after {
      ${props => (props.checked ? togleDrawOnCss : togleDrawOffCss)} */

  + ${ToggleLabel} {
      
    &::after {
      ${props => (props.checked ? togleDrawOnCss : togleDrawOffCss)}
    }
    
    &::before {
      ${props => (props.checked ? togleTextOnCss : togleTextOffCss)}
    } 
  }

`
// const Ad = styled.div`
//   width: 270px;
//   height: 100px;
//   background: red;
//   position: absolute;
//   /* right: ${PAD_LR}; */
//   right: 0;
//   bottom: 0;
//   background: transparent url(${imgCarbonAd}) center center no-repeat;
//   background-size: 100%;
// `

const navLink = css`
  display: inline-block;
  padding: 0 5px;
  font-family: ${BOOK_FONT};
  color: ${LIGHT};
  font-size: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  transition: color 0.15s ease-out;
  white-space: nowrap;
  overflow: visible;

  &::after {
    display: none;
  }

  &:last-child {
    padding-right: 0;
  }

  &:hover {
    color: ${ACCENT_COLOR};
  }

  @media screen and (max-width: 490px) {
    font-size: 12px;
  }
`

const NavLink = styled.a`
  ${navLink}
`

// OTHER PAGES

const PageWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: ${HEADER_H};

  @media ${MEDIA.SMALL} {
    top: ${HEADER_H_S};
  }
`

const ContentWrapper = styled.div`
  position: relative;
  padding: ${PAD_T} ${PAD_LR} ${PAD_B};
  max-width: 1000px;
  min-height: 100vh;
  margin: 0 auto;

  @media ${MEDIA.NOT_LARGE} {
    max-width: 90vw;
  }

  @media ${MEDIA.MEDIUM} {
    padding-top: 60px;
  }

  @media ${MEDIA.SMALL} {
    padding: ${PAD_T_S} ${PAD_LR_S} ${PAD_B_S};
  }

  &.page-404 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: calc(100vh - ${HEADER_H} - 70px);

    .title-404 {
      width: 100%;
      font-size: 22vw;
      color: ${GRAY};

      @media ${MEDIA.SMALL} {
        font-size: 40vw;
      }
    }

    h3 {
      padding-top: 0;
    }
  }
`

export {
  MainWrapper,
  AppWrapper,
  StyledHeader,
  LogoWrapper,
  Logo,
  AppContent,
  SidebarWrapper,
  StageWrapper,
  SvgWrapper,
  ToolbarWrapper,
  ToolbarButtons,
  // ButtonsWrapper,
  BtnInputLabel,
  BtnApply,
  BtnInput,
  BtnStyledLink,
  BtnSmall,
  CodeHeader,
  BtnCloseCodeWrapper,
  BtnCloseCode,
  // BtnPlay,
  // BtnCode,
  CodeWrapper,
  CodeContent,
  GeneratedCode,
  GeneratedCodeAnim,
  CheckboxWrapper,
  LabelCheck,
  CheckMark,
  BtnWrapper,
  SidebarFieldset,
  SidebarSeparator,
  SidebarButtons,
  FieldsetHeader,
  FieldsetContent,
  FieldsetTitle,
  SidebarInputWrapper,
  SidebarInput,
  SidebarSelect,
  SidebarLabel,
  ToggleWrapper,
  ToggleInput,
  ToggleLabel,
  ToggleDrawWrapper,
  ToggleDrawInput,
  // Ad,
  navLink,
  NavLink,
  PageWrapper,
  ContentWrapper,
}
