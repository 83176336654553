import React from 'react'
import PropTypes from 'prop-types'
import uuid from 'react-uuid'

// Styled Components
import {
  SidebarInputWrapper,
  SidebarSelect,
  SidebarLabel,
} from '../../styles/layout'

const Picker = ({ items, label, ...rest }) => (
  <SidebarInputWrapper>
    <SidebarLabel>
      {label}
      <SidebarSelect {...rest}>
        {items.map(e => (
          <option key={uuid()} value={e.value}>
            {e.name}
          </option>
        ))}
      </SidebarSelect>
    </SidebarLabel>
  </SidebarInputWrapper>
)

Picker.propTypes = {
  items: PropTypes.array,
  label: PropTypes.string,
}

export default Picker
