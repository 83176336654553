import React from 'react'
import PropTypes from 'prop-types'
// import { Link } from '@reach/router'
// import styled from 'styled-components'

// Utils
// import {
//   getTransitionCss,
//   getAnimationCss,
//   getSvgCircleLength,
//   getSvgEllipseLength,
// } from '../../utils'

// import MySwitch from '../../components/MySwitch'
// Constants
import { BTN_ICON } from '../../constants'
import { ToggleDraw } from '../Toggle'

import {
  // BtnCode,
  // BtnPlay,
  BtnLink,
} from '../Buttons'

// Styled Components
import {
  ToolbarWrapper,
  ToolbarButtons,
} from '../../styles/layout'


const Toolbar = ({
  onChangeDraw, toggleDrawChecked, onClickBtnPlay, onClickBtnCode, disabled, codeShown,
}) => (
  <ToolbarWrapper>
    <ToolbarButtons codeShown={codeShown} disabled={disabled}>
      <ToggleDraw id="draw-switch" disabled={disabled} onChange={e => onChangeDraw(e.target.checked)} checked={toggleDrawChecked} />
      {/* <BtnPlay type="button" onClick={onClickBtnPlay} disabled={disabled} />
      <BtnCode type="button" onClick={onClickBtnCode} disabled={disabled} /> */}

      <BtnLink type="button" kind="toolbar" icon={BTN_ICON.PLAY} text="Play" onClick={onClickBtnPlay} disabled={disabled} />
      <BtnLink type="button" kind="toolbar" icon={BTN_ICON.CODE} text="Get Code" onClick={onClickBtnCode} disabled={disabled} />
    </ToolbarButtons>
  </ToolbarWrapper>
)

Toolbar.propTypes = {
  onChangeDraw: PropTypes.func,
  onClickBtnPlay: PropTypes.func,
  onClickBtnCode: PropTypes.func,
  toggleDrawChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  codeShown: PropTypes.bool,
}

export default Toolbar
