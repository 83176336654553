
import React from 'react'

const IconOptions = () => (
  <svg
    className="IconOptions"
    viewBox="0 0 70 70"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M61 38c0-1.0552743.5532677-1.4171575.5532677-2.8241899 0-1.4070324 0-2.4623067-.3570527-3.517581l7.4981055-5.9798877c.7141053-.7035162.7141053-1.4070324.3570526-2.1105486l-7.1410528-12.3115336c-.3570526-.3517581-1.0710179-.7035162-2.1423158-.3517581l-8.926316 3.517581c-1.7852632-1.4070324-3.9275791-2.4623067-6.0698949-3.517581L43.343583 1.7587905C43.7006356.7035162 42.6293377 0 41.9153724 0H27.6332668c-.7142453 0-1.7852632.7035162-1.7852632 1.4070324l-1.4282105 9.4974687c-2.1423159.7035162-3.9275791 2.1105486-6.0698949 3.517581l-8.5692634-3.517581c-1.07115793-.3517581-1.78526321 0-2.49950851.7035162L.14007349 23.9195509c-.35705265.3517581 0 1.4070324.71410527 2.1105486l7.49810545 5.6281296c0 1.0552743-.35705264 2.1105486-.35705264 3.517581 0 1.4070324 0 2.4623067.35705264 3.517581L.85417876 44.6732788c-.71410528.7036541-.71410528 1.4070324-.35705264 2.1106865l7.14105281 12.3115336c.35705264.3517581 1.0710179.7035162 2.14231585.3517581l8.92631602-3.517581c1.7852632 1.4070324 3.927579 2.4623067 6.0698949 3.517581l1.4282105 9.1457106c0 .7035162.7141053 1.4070324 1.7852632 1.4070324h14.2821057c.7142453 0 1.7852632-.7036541 1.7852632-1.4070324l1.4282105-9.1457106c2.1423159-1.0552743 4.2846317-2.1105486 6.0698949-3.517581l8.926316 3.517581c.7142453.3517581 1.7852632 0 2.1424559-.7036542l7.1410528-12.3115335c.3570526-.7035162.3570526-1.7587905-.3571927-2.1105486L61 38zM35.5 53C26 53 18 45 18 35.5S26 18 35.5 18 53 26 53 35.5 45 53 35.5 53z"
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
    />
  </svg>
)

export default IconOptions
