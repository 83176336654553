import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'
import styled, { css } from 'styled-components'

import {
  Social,
} from '..'

import { IconClose } from '../svgs'
import { BtnSmall } from '../../styles/layout'

import {
  PAD_LR,
  // LIGHT_FONT,
  LIGHT,
  // EASE,
  GRAY,
  MEDIA,
  ACCENT_COLOR,
  // BOOK_FONT,
  // MEDIUM_FONT,
  DEMI_FONT,
  LIGHTER_GRAY,
} from '../../constants'

const menuShown = css`
  opacity: 1;
  pointer-events: all;
`
const menuHidden = css`
  opacity: 0;
  pointer-events: none;
  transition-duration: 0.55s;
  transition-delay: .3s;
`

const StyledMainNavWrapper = styled.nav`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  padding: ${PAD_LR};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${GRAY};
  /* backdrop-filter: blur(10px); */
  z-index: 1000;
  transition: all 0.3s ease-out;

  ${props => (props.shown ? menuShown : menuHidden)}

  @keyframes fade-in-bottom {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes fade-out-bottom {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(50px);
      opacity: 0;
    }
  }
`

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
`

const ItemShown = css`
  opacity: 1;

  &:nth-child(1) {
    animation-delay: 0.11s;
  }
  &:nth-child(2) {
    animation-delay: 0.14s;
  }
  &:nth-child(3) {
    animation-delay: 0.17s;
  }
  &:nth-child(4) {
    animation-delay: 0.2s;
  }

  animation: fade-in-bottom 0.2s ease-out both;
`

const ItemHidden = css`
  opacity: 1;

  &:nth-child(4) {
    animation-delay: 0.05s;
  }
  &:nth-child(3) {
    animation-delay: 0.1s;
  }
  &:nth-child(2) {
    animation-delay: 0.15s;
  }
  &:nth-child(1) {
    animation-delay: 0.2s;
  }

  animation: fade-out-bottom 0.2s ease-out both;
`

const StyledItem = styled.li`
  display: inline-block;
  margin: 0;
  padding: 7px 0;
  text-align: center;
  
  ${props => (props.shown ? ItemShown : ItemHidden)}

  @media ${MEDIA.SMALL} {
    padding: 6px 0;
  }
`

const StyledLink = styled(Link)`
  text-transform: uppercase;
  font-family: ${DEMI_FONT};
  font-size: 27px;
  letter-spacing: 2px;
  color: ${LIGHT};
  text-decoration: none;
  transition: all 0.15s ease-out;

  @media ${MEDIA.SMALL} {
    font-size: 24px;
  }

  /* a&[aria-current='page'] {
    color: ${LIGHTER_GRAY};
    text-decoration: line-through; 
    pointer-events: none;
    position: relative;
    transition: all 0.15s ease-out;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 40%;
      left: -5px;
      right: -5px;
      height: 2px;
      background-color: ${LIGHTER_GRAY};
    }
  } */

  &:hover {
    color: ${ACCENT_COLOR};
  }
`

const BtnCloseNav = styled(BtnSmall)`
  position: absolute;
  right: 20px;
  top: 12px;
  border: none;

  @media ${MEDIA.SMALL} {
    right: 12px;
    top: 10px;
  }

  svg {
    width: 16px;
    height: 16px;
    transform: scale(1);
  }

  &:hover {
    background: transparent;

    svg.iconClose {
      g {
        stroke: ${LIGHT};
      }
    }
  }
`

const socialShown = css`
  animation: fade-in-bottom 0.2s ease-out both .23s;
`
const socialHidden = css`
    animation: fade-out-bottom 0.2s ease-out both 0s;
`

const MainNavSocial = styled.div`
  margin-top: 35px;
  ${props => (props.shown ? socialShown : socialHidden)}

  h4 {
    font-size: 26px;
    letter-spacing: 1px;
    color: ${LIGHT};
    text-transform: none;

    svg {
      height: 19px;
      margin-left: 12px;
      margin-bottom: 2px;
    }

    @media ${MEDIA.SMALL} {
      font-size: 19px;

      svg {
        height: 17px;
        margin-left: 10px;
        margin-bottom: 2px;

      }
    }
  }

  div {
    margin-left: 0px;

    &::before {
      font-size: 22px;
      margin: 0 6px 0 18px;

      @media ${MEDIA.SMALL} {
        font-size: 18px;
        margin: 0 2px 0 10px;
      }
    }
    
    a {
      svg {
        height: 20px;
        margin-bottom: 5px;
        margin-left: 6px;

        @media ${MEDIA.SMALL} {
          height: 15px;
          margin-left: 2px;
        }
      }
    }
  }
`

const MainNav = ({ shown, closeMenu }) => (
  <StyledMainNavWrapper shown={shown}>
    <StyledList onClick={closeMenu}>
      <StyledItem shown={shown}>
        <StyledLink to="/">Home</StyledLink>
      </StyledItem>
      <StyledItem shown={shown}>
        <StyledLink to="/about">About</StyledLink>
      </StyledItem>
      <StyledItem shown={shown}>
        <StyledLink to="/how-to">How to</StyledLink>
      </StyledItem>
      <StyledItem shown={shown}>
        <StyledLink to="/support-us">Support us</StyledLink>
      </StyledItem>
    </StyledList>

    <MainNavSocial shown={shown}>
      <Social />
    </MainNavSocial>

    <BtnCloseNav type="button" onClick={closeMenu}>
      <IconClose />
      Close
    </BtnCloseNav>
  </StyledMainNavWrapper>
)

MainNav.propTypes = {
  shown: PropTypes.bool,
  closeMenu: PropTypes.func,
}

export default MainNav
