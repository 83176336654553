/* eslint-disable max-len */
const INITIAL_PIC = `
<svg viewBox="0 0 564 242" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <g stroke-width="2" stroke="none" fill="none" fill-rule="evenodd">
    <path d="M284.896413 1.77679122c.882345.50911399 1.614955 1.2417713 2.124036 2.124173l21.099094 36.57152808-23.135682 15.8357502c4.105694 3.2404436 6.948421 5.8686448 8.528184 7.8846038 1.579762 2.0159589 3.442094 5.5716267 5.586996 10.6670034l18.980455-17.1235485 38.506082 66.7434478c1.599912 2.773164.648949 6.318327-2.124036 7.918342-.880865.50826-1.879934.775822-2.896898.775822h-1.922491c-20.321792-10.300399-43.304909-16.104436-67.642638-16.104436-24.337728 0-47.320846 5.804037-67.642638 16.104436h-1.92249c-3.201432 0-5.796706-2.595441-5.796706-5.79708 0-1.01703.267545-2.016163.775772-2.897084L276.978581 3.90096422c1.599913-2.77316415 5.144847-3.7241888 7.917832-2.124173zm-22.79159 66.41506168c-11.912588 9.3077337-11.944524 23.5356033-27.180843 31.0058222 21.073897 5.2094339 33.551253 4.4950599 46.817544-5.8703705 13.266291-10.3654307 12.78672-21.586947 8.366252-27.2452528-4.420468-5.6583057-16.090365-7.1979325-28.002953 2.1098011z" stroke="#00CCAE" fill="#00CCAE"/>
    <path d="M20.7357156 241c-3.6819127 0-7.2356044-.568639-10.66118144-1.705935C6.64895709 238.15677 3.95747261 236.68295 2 234.872561l2.58664731-5.431139c1.86425963 1.671128 4.25280646 3.028899 7.16571213 4.073354 2.91290566 1.044455 5.90732776 1.566675 8.98335616 1.566675 4.0547647 0 7.0841412-.684688 9.0882203-2.054085 2.0040791-1.369396 3.0061036-3.191363 3.0061036-5.465954 0-1.671128-.547618-3.028899-1.6428706-4.073354-1.0952525-1.044455-2.4468205-1.845192-4.0547444-2.402235-1.6079239-.557043-3.8799563-1.183706-6.8161652-1.88001-3.6819128-.881984-6.653032-1.763955-8.91344679-2.645939-2.26041481-.881985-4.19455516-2.239756-5.8024791-4.073355-1.60792393-1.833599-2.41187384-4.305438-2.41187384-7.415594 0-2.599532.68743543-4.943718 2.06232691-7.032629 1.37489148-2.08891 3.46050066-3.760013 6.25689011-5.013359 2.79638941-1.253346 6.26852091-1.880009 10.41649861-1.880009 2.8896024 0 5.7325557.371356 8.5289452 1.114079 2.7963894.742724 5.1965877 1.810373 7.2006668 3.20298l-2.3070098 5.570399c-2.0506855-1.299766-4.2411578-2.286181-6.5714823-2.959275-2.3303245-.673093-4.6140083-1.009634-6.8511199-1.009634-3.9615517 0-6.9326709.719502-8.9134468 2.158529-1.98077586 1.439027-2.97114893 3.295808-2.97114893 5.570399 0 1.671129.5592695 3.0289 1.67782528 4.073355 1.11855575 1.044455 2.50507805 1.856797 4.15960855 2.437049 1.6545304.580253 3.9032598 1.195312 6.7462558 1.845195 3.6819127.881984 6.6413805 1.763956 8.8784921 2.64594 2.2371115.88198 4.1596004 2.22815 5.7675244 4.038539 1.6079239 1.810389 2.4118738 4.247414 2.4118738 7.311149 0 2.553112-.6990869 4.885694-2.0972816 6.997814-1.3981947 2.11212-3.5187582 3.783223-6.3617542 5.013359C28.3791277 240.384941 24.8836933 241 20.7357156 241z" stroke="#1D1B1D" fill="#1D1B1D"/>
    <path stroke="#1D1B1D" fill="#1D1B1D" d="M102.289855 191.724638L80.8535704 240.42029h-6.9126781l-21.5061097-48.695652h7.5411034l17.5959079 40 17.735558-40z"/>
    <path d="M152.031506 215.793944h6.654064v19.008987c-2.402868 1.99607-5.198472 3.527914-8.386893 4.595579C147.110255 240.46617 143.783256 241 140.31758 241c-4.898155 0-9.311049-1.079254-13.238815-3.237795-3.927766-2.15854-7.012171-5.12939-9.253308-8.912638-2.241137-3.783249-3.361689-8.04224-3.361689-12.777103 0-4.734863 1.120552-9.00546 3.361689-12.811918 2.241137-3.806459 5.337094-6.777309 9.287965-8.912639 3.95087-2.13533 8.398421-3.202979 13.342785-3.202979 3.881557 0 7.404941.638268 10.570258 1.914824 3.165317 1.276556 5.856952 3.144942 8.074984 5.605214l-4.297416 4.31706c-3.881557-3.760038-8.571701-5.640029-14.070574-5.640029-3.69672 0-7.012167.800736-9.946439 2.402234-2.934273 1.601498-5.233136 3.829635-6.896661 6.684479-1.663524 2.854844-2.495274 6.069396-2.495274 9.643754 0 3.527937.83175 6.719279 2.495274 9.574123 1.663525 2.854844 3.962388 5.094586 6.896661 6.719294 2.934272 1.624708 6.226614 2.43705 9.877126 2.43705 4.343647 0 8.132729-1.044439 11.36736-3.13335v-15.875637z" stroke="#1D1B1D" fill="#1D1B1D"/>
    <path d="M233.231687 191.724638l22.101646 48.695652h-7.297713l-5.351657-12.173913h-25.854756l-5.351657 12.173913h-7.158709l22.032144-48.695652h6.880702zm-3.475102 7.095652l-10.494807 23.860869h20.989614l-10.494807-23.860869z" stroke="#1D1B1D" fill="#1D1B1D"/>
    <path d="M292.229824 191.724638c6.285416 0 11.230485 1.507231 14.835356 4.521739 3.60487 3.014508 5.407279 7.165191 5.407279 12.452174 0 3.75654-.924312 6.956508-2.772964 9.6-1.848652 2.643491-4.482941 4.56811-7.902946 5.773913l11.507799 16.347826h-7.556326l-10.467938-14.956522c-1.294056.092754-2.310799.139131-3.05026.139131h-11.993067v14.817391h-6.932409v-48.695652h18.925476zm-.207972 6.052174h-11.785095v21.913043h11.785095c4.390548 0 7.741179-.950715 10.051993-2.852174 2.310815-1.901459 3.466205-4.614475 3.466205-8.13913 0-3.524656-1.15539-6.226078-3.466205-8.104348-2.310814-1.87827-5.661445-2.817391-10.051993-2.817391z" stroke="#1D1B1D" fill="#1D1B1D"/>
    <path stroke="#1D1B1D" fill="#1D1B1D" d="M343.217992 197.776812h-16.580311v-6.052174h40v6.052174H350.05737v42.643478h-6.839378z"/>
    <path stroke="#1D1B1D" fill="#1D1B1D" d="M384.608696 191.724638h6.956521v48.695652h-6.956521z"/>
    <path d="M431.170498 241c-3.681913 0-7.235604-.568639-10.661181-1.705935-3.425577-1.137295-6.117062-2.611115-8.074534-4.421504l2.586647-5.431139c1.86426 1.671128 4.252806 3.028899 7.165712 4.073354 2.912906 1.044455 5.907328 1.566675 8.983356 1.566675 4.054765 0 7.084141-.684688 9.088221-2.054085 2.004079-1.369396 3.006103-3.191363 3.006103-5.465954 0-1.671128-.547618-3.028899-1.64287-4.073354-1.095253-1.044455-2.446821-1.845192-4.054745-2.402235-1.607924-.557043-3.879956-1.183706-6.816165-1.88001-3.681913-.881984-6.653032-1.763955-8.913447-2.645939-2.260415-.881985-4.194555-2.239756-5.802479-4.073355-1.607924-1.833599-2.411874-4.305438-2.411874-7.415594 0-2.599532.687436-4.943718 2.062327-7.032629 1.374892-2.08891 3.460501-3.760013 6.25689-5.013359 2.79639-1.253346 6.268521-1.880009 10.416499-1.880009 2.889602 0 5.732556.371356 8.528945 1.114079 2.796389.742724 5.196588 1.810373 7.200667 3.20298l-2.30701 5.570399c-2.050686-1.299766-4.241158-2.286181-6.571482-2.959275-2.330325-.673093-4.614009-1.009634-6.85112-1.009634-3.961552 0-6.932671.719502-8.913447 2.158529-1.980776 1.439027-2.971149 3.295808-2.971149 5.570399 0 1.671129.55927 3.0289 1.677825 4.073355 1.118556 1.044455 2.505078 1.856797 4.159609 2.437049 1.65453.580253 3.90326 1.195312 6.746256 1.845195 3.681912.881984 6.64138 1.763956 8.878492 2.64594 2.237111.88198 4.1596 2.22815 5.767524 4.038539 1.607924 1.810389 2.411874 4.247414 2.411874 7.311149 0 2.553112-.699087 4.885694-2.097282 6.997814-1.398194 2.11212-3.518758 3.783223-6.361754 5.013359C438.81391 240.384941 435.318476 241 431.170498 241z" stroke="#1D1B1D" fill="#1D1B1D"/>
    <path stroke="#1D1B1D" fill="#1D1B1D" d="M479.449876 197.776812h-16.580311v-6.052174h40v6.052174h-16.580311v42.643478h-6.839378z"/>
    <path d="M539.898353 191.724638L562 240.42029h-7.297714l-5.351656-12.173913h-25.854757l-5.351656 12.173913h-7.15871l22.032145-48.695652h6.880701zm-3.475101 7.095652l-10.494808 23.860869h20.989615l-10.494807-23.860869z" stroke="#1D1B1D" fill="#1D1B1D"/>
  </g>
</svg>
`

export default INITIAL_PIC
