import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ChromePicker } from 'react-color'
import styled from 'styled-components'

// Constants
import {
  DARK_GRAY,
  INPUT_H,
  INPUT_W,
  INPUT_MARGIN_B,
  INPUT_BORDER,
  // BOOK_FONT,
  // LIGHT,
  MEDIA,
  // BORDER_RAD,
} from '../../constants'

const ColorPickerWrapper = styled.div`
  height: ${INPUT_H};
  width: 100%;
  margin-bottom: ${INPUT_MARGIN_B};
  position: relative;

  @media ${MEDIA.NOT_LARGE} {
    margin-bottom: 10px;
    height: auto;
  }
`

const PopoverWrapper = styled.div`
  z-index: 2;
  position: absolute;
  top: calc(${INPUT_H} + ${INPUT_MARGIN_B});
  right: 0;
`

const Popover = styled.div`
  z-index: 2; 
  position: absolute;
  top: 0;
  right: 0;
  /* animation: scale-in-tr .25s cubic-bezier(0.250, 0.460, 0.450, 0.940) forwards; */

  @media ${MEDIA.NOT_LARGE} {
    top: 20px;
  }

  @keyframes scale-in-tr {
    0% {
      transform: scale(0);
      transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      transform: scale(1);
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }
`

const Cover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const BtnColor = styled.button`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: ${INPUT_H};
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0;
  margin: 0;
  font-size: 15px;

  @media ${MEDIA.NOT_LARGE} {
    flex-direction: column;
    height: auto;
    width: 100%;
    text-align: left;
    align-items: stretch;
  }

`

const Swatch = styled.div`
  width: ${INPUT_W};
  height: ${INPUT_H};
  border-radius: ${INPUT_BORDER};
  margin-left: 12px;
  cursor: pointer;
  background-color: ${props => (props.color ? props.color : DARK_GRAY)};
 
  @media ${MEDIA.NOT_LARGE} {
    width: 100%;    
    margin-left: 0px;
    margin-top: 3px;
  }
`

const BtnColorPicker = ({ onChangeComplete, color }) => {
  const [pickerVisible, setPickerVisible] = useState(false)

  const handleClick = () => setPickerVisible(pickerVisible => !pickerVisible)

  const handleClose = () => setPickerVisible(false)

  return (
    <ColorPickerWrapper>
      <BtnColor type="button" onClick={handleClick}>
        background color:
        <Swatch color={color} />
      </BtnColor>
      {/* <BtnColor type="button" color={color} onClick={handleClick} /> */}

      { pickerVisible ? (
        <PopoverWrapper>
          <Cover onClick={handleClose} />
          <Popover>
            <ChromePicker color={color} onChangeComplete={onChangeComplete} />
          </Popover>
        </PopoverWrapper>
      ) : null }
    </ColorPickerWrapper>
  )
}

BtnColorPicker.propTypes = {
  onChangeComplete: PropTypes.func,
  color: PropTypes.string,
}


export default BtnColorPicker
