import React from 'react'

const IconPlay = () => (
  <svg
    className="iconPlay"
    viewBox="0 0 28 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.70107 22.41921L4.774091 38.499c-1.336096.9798-3.213503.69097-4.193306-.64512C.203448 37.33933 0 36.71786 0 36.07978V3.92022c0-1.65686 1.343146-3 3-3 .63808 0 1.25954.20344 1.774091.58078L26.70107 17.58079c1.336096.9798 1.624928 2.85721.645124 4.1933-.180893.24667-.39845.46423-.645124.64512z"
      stroke="none"
      fillRule="evenodd"
    />
  </svg>
)

export default IconPlay
