import React from 'react'
import PropTypes from 'prop-types'

// Styled Components
import {
  ToggleWrapper,
  ToggleInput,
  ToggleLabel,
} from '../../styles/layout'


const ToggleSmall = ({ label, id, ...rest }) => (
  <ToggleWrapper>
    <ToggleInput id={id} {...rest} />
    <ToggleLabel htmlFor={id}>
      {label}
    </ToggleLabel>
  </ToggleWrapper>
)

ToggleSmall.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
}

export default ToggleSmall
