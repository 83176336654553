const getTransitionCss = ({
  svgElemClass,
  strokeDasharrayStartValue,
  strokeDashoffsetStartValue,
  transValue,
  shouldAnimateFill,
  shouldAnimateStroke,
  strokeDashoffsetEndValue,
  fillEndValue,
  i,
}) => `
svg .${svgElemClass}-${i + 1} {
  ${
  shouldAnimateStroke
    ? `stroke-dashoffset: ${strokeDashoffsetStartValue}px;
  stroke-dasharray: ${strokeDasharrayStartValue}px;`
    : ''
}
  ${shouldAnimateFill && fillEndValue ? 'fill: transparent;' : ''}
  transition: ${transValue};
}

svg.active .${svgElemClass}-${i + 1} {
  ${shouldAnimateStroke ? `stroke-dashoffset: ${strokeDashoffsetEndValue}px;` : ''}
  ${shouldAnimateFill && fillEndValue ? `fill: ${fillEndValue};` : ''}
}

`

export default getTransitionCss
