import { createGlobalStyle } from 'styled-components'
import {
  DARK_GRAY,
  // GRAY,
  LIGHT,
  LIGHTER_GRAY,
} from '../constants'

const Sanitize = createGlobalStyle`
  *,
  ::before,
  ::after {
    background-repeat: no-repeat; /* 1 */
    box-sizing: inherit; /* 2 */
  }

  ::before,
  ::after {
    text-decoration: inherit; /* 1 */
    vertical-align: inherit; /* 2 */
  }

  html {
    box-sizing: border-box; /* 1 */
    cursor: default; /* 2 */
    -ms-text-size-adjust: 100%; /* 3 */
    -webkit-text-size-adjust: 100%; /* 3 */
  }

  article,
  aside,
  footer,
  header,
  nav,
  section {
    display: block;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased; 
  }

  h1 {
    font-size: 2em;
    margin: .67em 0;
  }

  figcaption,
  figure,
  main { /* 1 */
    display: block;
  }

  /**
  * Add the correct margin in IE 8.
  */

  figure {
    margin: 1em 40px;
  }

  /**
  * 1. Add the correct box sizing in Firefox.
  * 2. Show the overflow in Edge and IE.
  */

  hr {
    box-sizing: content-box; /* 1 */
    height: 0; /* 1 */
    overflow: visible; /* 2 */
  }

  /**
  * Remove the list style on navigation lists in all browsers (opinionated).
  */

  nav ol,
  nav ul {
    list-style: none;
  }

  pre {
    font-family: monospace, monospace; /* 1 */
    font-size: 1em; /* 2 */
  }
  a {
    background-color: transparent; /* 1 */
    -webkit-text-decoration-skip: objects; /* 2 */
  }

  abbr[title] {
    border-bottom: none; /* 1 */
    text-decoration: underline; /* 2 */
    text-decoration: underline dotted; /* 2 */
  }

  b,
  strong {
    font-weight: inherit;
  }

  b,
  strong {
    font-weight: bolder;
  }

  code,
  kbd,
  samp {
    font-family: monospace, monospace; /* 1 */
    font-size: 1em; /* 2 */
  }

  dfn {
    font-style: italic;
  }

  mark {
    background-color: ${LIGHT};
    color: ${DARK_GRAY};
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -.25em;
  }

  sup {
    top: -.5em;
  }

  /*
  * Remove the text shadow on text selections (opinionated).
  * 1. Restore the coloring undone by defining the text shadow (opinionated).
  */

  ::-moz-selection {
    /* background-color: ${DARK_GRAY};
    color: #2e2e2e; */
    text-shadow: none;
  }

  ::selection {
    /* background-color: #2e2e2e12;
    color: #2e2e2e; */
    text-shadow: none;
    /* background-color: ${LIGHTER_GRAY}; */
    background: transparent;
  }

  /* Embedded content (https://html.spec.whatwg.org/multipage/embedded-content.html#embedded-content)
    ========================================================================== */

  /*
  * Change the alignment on media elements in all browers (opinionated).
  */

  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
  }

  /**
  * Add the correct display in IE 9-.
  */

  audio,
  video {
    display: inline-block;
  }

  /**
  * Add the correct display in iOS 4-7.
  */

  audio:not([controls]) {
    display: none;
    height: 0;
  }

  /**
  * Remove the border on images inside links in IE 10-.
  */

  img {
    border-style: none;
  }

  /**
  * Change the fill color to match the text color in all browsers (opinionated).
  */

  /* svg {
    fill: currentColor;
  } */

  /**
  * Hide the overflow in IE.
  */

  svg:not(:root) {
    overflow: hidden;
  }

  /* Tabular data (https://html.spec.whatwg.org/multipage/tables.html#tables)
    ========================================================================== */

  /**
  * Collapse border spacing
  */

  table {
    border-collapse: collapse;
  }

  /* Forms (https://html.spec.whatwg.org/multipage/forms.html#forms)
    ========================================================================== */

  /**
  * Remove the margin in Firefox and Safari.
  */

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
  }

  /**
  * Inherit styling in all browsers (opinionated).
  */

  button,
  input,
  select,
  textarea {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  /**
  * Show the overflow in IE.
  * 1. Show the overflow in Edge.
  */

  button,
  input { /* 1 */
    overflow: visible;
  }


  button,
  select { /* 1 */
    text-transform: none;
  }

  button,
  html [type="button"], /* 1 */
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button; /* 2 */
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  legend {
    box-sizing: border-box; /* 1 */
    color: inherit; /* 2 */
    display: table; /* 1 */
    max-width: 100%; /* 1 */
    padding: 0; /* 3 */
    white-space: normal; /* 1 */
  }


  progress {
    display: inline-block; /* 1 */
    vertical-align: baseline; /* 2 */
  }


  textarea {
    overflow: auto; /* 1 */
    resize: vertical; /* 2 */
  }


  [type="checkbox"],
  [type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
  }

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    -webkit-appearance: textfield; /* 1 */
    outline-offset: -2px; /* 2 */
  }

  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
  }

  details, /* 1 */
  menu {
    display: block;
  }

  summary {
    display: list-item;
  }

  canvas {
    display: inline-block;
  }

  template {
    display: none;
  }

  a,
  area,
  button,
  input,
  label,
  select,
  summary,
  textarea,
  [tabindex] {
    -ms-touch-action: manipulation; /* 1 */
    touch-action: manipulation;
  }

  [hidden] {
    display: none;
  }

  [aria-busy="true"] {
    cursor: progress;
  }

  [aria-controls] {
    cursor: pointer;
  }

  [aria-hidden="false"][hidden]:not(:focus) {
    clip: rect(0, 0, 0, 0);
    display: inherit;
    position: absolute;
  }

  [aria-disabled] {
    cursor: default;
  }
`

export default Sanitize
