import { createGlobalStyle } from 'styled-components'

import {
  BOOK_FONT,
  // DEMI_FONT,
  // LIGHT_FONT,
  BORDER_RAD,
  SHADOW,
  ACCENT_COLOR,
  PAD_LR,
  MEDIA,
  PAD_LR_S,
  PAD_LR_XS,
} from '../constants'

const NotificationStyles = createGlobalStyle`
  .notification-container-top-center {
    position: fixed;
    z-index: 8000;
    /* transform: translateX(-50%); */
    top: 0;
    left: 0;
    right: 0;
  }
  .notification-container-bottom-center {
    position: fixed;
    z-index: 8000;
    transform: translateX(-50%);
    margin-bottom: -20px;
    bottom: 20px;
    left: 50%
  }
  .notification-container-top-left {
    position: fixed;
    left: 20px;
    top: 20px;
    z-index: 8000
  }
  .notification-container-top-right {
    position: fixed;
    right: ${PAD_LR};
    top: 12px;
    z-index: 8000
  }
  .notification-container-bottom-left {
    position: fixed;
    left: 20px;
    bottom: 20px;
    z-index: 8000;
    margin-bottom: -20px
  }
  .notification-container-bottom-right {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 8000;
    margin-bottom: -20px
  }
  .notification-container-mobile-top {
    z-index: 8000;
    position: fixed;
    right: 0;
    left: 0;
    top: 0
  }

  .notification-container-mobile-bottom {
    z-index: 8000;
    position: fixed;
    right: 20px;
    left: 20px;
    bottom: 5px
  }
  .notification-default {
    background-color: #007bff;
  }
  .notification-success {
     /* background-color: hsl(171,100%,30%);*/
    background-color: ${ACCENT_COLOR}; 
  }
  .notification-danger {
    background-color: #dc3545;
  }
  .notification-info {
    background-color: #17a2b8;
  }
  .notification-warning {
    background-color: #eab000;
  }
  .notification-awesome {
    background-color: #685dc3;
  }
  .notification-close span,
  .notification-message,
  .notification-title {
    color: #fff
  }
  .notification-item,
  .notification-item-root,
  .notification-message,
  .notification-title {
    font-family: ${BOOK_FONT};
  }

  .notification-close {
    font-size: 28px;
    position: absolute;
    right: ${PAD_LR};

    @media ${MEDIA.SMALL} {
      right: ${PAD_LR_S};
      top: 5px;
    }
    
    @media ${MEDIA.XS} {
      right: ${PAD_LR_XS};
    }
  }

  .nc-center {
    top: 50%;
    left: 50%;
    position: fixed;
    z-index: 8000;
    pointer-events: all
  }
  .nc-box {
    left: -50%;
    position: relative;
    transform: translateY(-50%)
  }
  .notification-container-mobile-bottom .notification-item,
  .notification-container-mobile-bottom .notification-item-root,
  .notification-container-mobile-top .notification-item,
  .notification-container-mobile-top .notification-item-root {
    max-width: 100%;
    width: 100%
  }

  .notification-item {
    display: flex;
    position: relative;
    border-radius: 0 0 ${BORDER_RAD} ${BORDER_RAD};
    cursor: pointer;
    box-shadow: ${SHADOW};
  }

  .notification-container-bottom-right .notification-item-root,
  .notification-container-top-right .notification-item-root {
    margin-left: auto
  }
  .notification-container-bottom-left .notification-item-root,
  .notification-container-top-left .notification-item-root {
    margin-right: auto
  }
  .notification-container-mobile-bottom .notification-item-root,
  .notification-container-mobile-top .notification-item-root {
    margin-left: auto;
    margin-right: auto
  }
  .notification-item-root {
    width: auto;
    /*max-width: 360px;*/
    margin-bottom: 12px;
  }

  .notification-title {
    font-family: ${BOOK_FONT};
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 5px;
    margin-bottom: 10px
  }

  .notification-message {
    font-family: ${BOOK_FONT};
    font-size: 17px;
    letter-spacing: 1px;
    line-height: 1.1;
    word-wrap: break-word;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;

    @media ${MEDIA.SMALL} {
      font-size: 15px;
    }

  }
  .notification-invisible {
    visibility: hidden;
    max-width: 600px
  }
  .notification-visible {
    visibility: visible
  }

  .notification-content {
    padding: 20px 50px 20px ${PAD_LR};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media ${MEDIA.SMALL} {
      /* padding: 15px ${PAD_LR_S}; */
      padding: 15px 35px 15px ${PAD_LR_S};
    }
    
    @media ${MEDIA.XS} {
      padding: 12px 32px 12px ${PAD_LR_XS};
    }
  }

  /* ----------------------------------------------
  * Generated by Animista on 2019-8-8 16:25:31
  * w: http://animista.net, t: @cssanimista
  * ---------------------------------------------- */

  .fade-in {
    animation: fade-in 1s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
  }

  .fade-out {
    animation: fade-out 1s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
  }

  .slide-in-top {
    animation: slide-in-top 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
  }

  .slide-out-top {
    animation: slide-out-top 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
  }

  /**
  * ----------------------------------------
  * animation fade-in
  * ----------------------------------------
  */
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /**
  * ----------------------------------------
  * animation fade-out
  * ----------------------------------------
  */
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  /**
  * ----------------------------------------
  * animation slide-in-top
  * ----------------------------------------
  */
  @keyframes slide-in-top {
    0% {
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  /**
  * ----------------------------------------
  * animation slide-out-top
  * ----------------------------------------
  */
  @keyframes slide-out-top {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-50px);
      opacity: 0;
    }
  }


`

export default NotificationStyles
