import React from 'react'
import { Link } from '@reach/router'
import styled from 'styled-components'

import {
  Social,
} from '..'

import {
  PAD_LR,
  PAD_LR_S,
  PAD_LR_XS,
  SHADOW,
  LIGHT_FONT,
  LIGHTER_GRAY,
  GRAY,
  MEDIA,
} from '../../constants'

import { navLink, NavLink } from '../../styles/layout'

const StyledFooter = styled.footer`
  display: flex;
  align-items: center; 
  justify-content: space-between;
  margin: 0;
  padding: 18px ${PAD_LR};
  background-color: ${GRAY};
  box-shadow: ${SHADOW};
  z-index: 100;
  
  p {
    padding: 0;
  }

  @media ${MEDIA.SMALL} {
    flex-direction: column-reverse;
    padding: 14px ${PAD_LR_S};
  }
  
  @media ${MEDIA.XS} {
    padding: 12px ${PAD_LR_XS};
  }
`

const FooterNav = styled.div`
  display: inline-block;
  text-align: right;
  
  @media ${MEDIA.NOT_LARGE} {
    display: none;
  }
  /* @media ${MEDIA.SMALL} {
    margin-bottom: 8px;
    text-align: center;
    font-size: 12px;
  } */
`

const FooterNavLink = styled(Link)`
  ${navLink}
  margin: 0 7px;
`

const NavSeparator = styled.div`
  width: 1px;
  height: 12px;
  background: hsl(300,1%,40%);
  display: inline-block;
  transform: skewX(-15deg);

  &.last {
    
    @media screen and (max-width: 490px) {
      display: none
    }
  }
`
const Copyright = styled.div`
  color: ${LIGHTER_GRAY};
  font-family: ${LIGHT_FONT};
  font-size: 14px;
  letter-spacing: 1px;

  @media ${MEDIA.SMALL} {
    margin-top: 4px;

    /* ${NavLink} {
      font-size: 13px;
    } */
  }
`

const Footer = () => (
  <StyledFooter>

    <Copyright>
      by
      <NavLink href="https://twitter.com/sergej108" rel="noopener noreferrer" target="_blank">Sergej Skrjanec</NavLink>&
      <NavLink href="https://twitter.com/ana108" rel="noopener noreferrer" target="_blank">Ana Travas</NavLink> ©2019
    </Copyright>

    <div>
      <FooterNav>
        <FooterNavLink to="/about">About</FooterNavLink>
        <NavSeparator />
        <FooterNavLink to="/how-to">How to</FooterNavLink>
        <NavSeparator />
        <FooterNavLink to="/support-us">Support us</FooterNavLink>
        <NavSeparator className="last" />
      </FooterNav>

      <Social />

      {/* <SocialWrapper>
        <SocialTitle>
          <span>Share the</span>
          <IconHart />
        </SocialTitle>

        <SocialLinks>
          <NavLink href="http://twitter.com/intent/tweet?status=Check%20out%20this%20cool%20SVG%20drawing%20animation%20tool%20at%20https%3A%2F%2Fwww.svgartista.net%20%23svg%20%23svganimation%20via%20%40svgArtista" title="Share you love for SVG Artista on Twitter" rel="noopener noreferrer" target="_blank"><IconTwitter /></NavLink>
          <NavLink href="http://www.facebook.com/sharer/sharer.php?u=https://www.svgartista.net" title="Share you love for SVG Artista on Facebook" rel="noopener noreferrer" target="_blank"><IconFacebook /></NavLink>
          <NavLink href="http://www.linkedin.com/shareArticle?mini=true&url=http://www.svgartista.nett&title=SVG%20%Artista&summary=Check%20out%20this%20cool%20SVG%20drawing%20animation%20tool%20at%20https%3A%2F%2Fwww.svgartista.nett&source=www.Svgartista.net" title="Share you love for SVG Artista on Linkedin" rel="noopener noreferrer" target="_blank"><IconLinkedin /></NavLink>
        </SocialLinks>
      </SocialWrapper> */}
    </div>

  </StyledFooter>
)

export default Footer
