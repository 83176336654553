import React, { useState, lazy, Suspense } from 'react'
import { Router, createHistory, LocationProvider } from '@reach/router'
import ReactGA from 'react-ga4'

// Components
import { Header, MainNav } from './components'

// Styles
import Sanitize from './styles/sanitize'
import GlobalStyles from './styles/global'
import NotificationStyles from './styles/notifications'
// import Fonts from './styles/fonts'
import { MainWrapper } from './styles/layout'

// Pages
// import {
//   Home, About, HowTo, SupportUs, NotFound,
// } from './pages'

const Home = lazy(() => import('./pages/Home'))
const About = lazy(() => import('./pages/About'))
const HowTo = lazy(() => import('./pages/HowTo'))
const SupportUs = lazy(() => import('./pages/SupportUs'))
const NotFound = lazy(() => import('./pages/NotFound'))

const history = createHistory(window)
// const initReactGA = () => {
ReactGA.initialize([
  {
    trackingId: 'G-END3THJQQT',
    gaOptions: {
      anonymizeIp: true,
    },
  },
])
// ReactGA.initialize('UA-1623853-4', {
//   // debug: true,
//   gaOptions: {
//     // name: 'tracker1',
//     // userId: 123,
//     anonymizeIp: true,
//   },
// })
// }

history.listen(({ location }) => {
  // ReactGA.pageview(location.pathname + location.search)
  ReactGA.send({
    hitType: 'pageview',
    page: location.pathname + location.search,
  })
  // console.log(location.pathname)
  // console.log(location.search)
  // console.log(action)
})

const App = () => {
  const [isHeaderShown, setIsHeaderShown] = useState(true)
  const [isMenuShown, setIsMenuShown] = useState(false)

  const updateIsHeaderShown = isShown => setIsHeaderShown(isShown)
  const closeMenu = () => setIsMenuShown(false)
  const toggleMenu = () => setIsMenuShown(currIsMenuShown => !currIsMenuShown)

  return (
    <LocationProvider history={history}>
      <Sanitize />
      {/* <Fonts /> */}
      <GlobalStyles />
      <NotificationStyles />
      <Header shown={isHeaderShown} toggleMenu={toggleMenu} />
      <MainWrapper>
        <Suspense fallback={<div>Loading...</div>}>
          <Router>
            <Home path="/" updateIsHeaderShown={updateIsHeaderShown} />
            <About path="/about" />
            <HowTo path="/how-to" />
            <SupportUs path="/support-us" />
            <NotFound default />
          </Router>
        </Suspense>
      </MainWrapper>
      <MainNav shown={isMenuShown} closeMenu={closeMenu} />
    </LocationProvider>
  )
}

export default App
