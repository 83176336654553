import React, { useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash/throttle'
import styled from 'styled-components'

// Constants
import { BSA_REFRESH_INTERVAL, PAD_LR, DEMI_FONT } from '../../constants'

const BsaWrapper = styled.div`
  position: absolute;
  /* z-index: 1; */
  /* top: 0; */
  right: ${PAD_LR};
  bottom: 15px;
  /* left: 50%; */
  /* bottom: 0; */
  /* transform: translateX(-50%); */
  border-radius: 7px;
  overflow: hidden;
  /* box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1); */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu,
    Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif; */
  font-family: ${DEMI_FONT};

  @media (max-width: 767px) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }

  .native-button {
    text-decoration: none;
    z-index: 100;
    /* display: block; */
    display: flex;
    padding: 11px 20px;
    /* border-radius: 4px; */
    /* box-shadow: 0 1px 4px 1px hsla(0, 0%, 0%, .15); */
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: normal;
    font-size: 11px;
    cursor: pointer;

    user-select: none;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }

  .native-img {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
`

const CarbonCustom = ({
  customClass, btnClass = 'native-button', serve, placement,
}) => {
  const carbonWrapper = useRef(null)
  const timerID = useRef(null)
  // const [isActive, setIsActive] = useState(false)
  const mainClass = 'bsa-button'

  const classes = [mainClass, customClass].filter(c => c).join(' ')

  const initCarbon = useCallback(() => {
    // Don't initialise Carbon if in react-snap or node environments (e.g. Gatsby)
    const shouldInitCarbon = typeof window !== 'undefined' && navigator.userAgent !== 'ReactSnap' && (!carbonWrapper.current.hasChildNodes() || !carbonWrapper.current.children.length)

    if (shouldInitCarbon) {
      // console.log('adding carbon')
      // console.log(navigator.userAgent)
      // eslint-disable-next-line no-underscore-dangle
      if (typeof window._bsa !== 'undefined' && window._bsa) {
        // eslint-disable-next-line no-underscore-dangle
        window._bsa.init('custom', `${serve}`, `placement:${placement}`, {
          target: `.${mainClass}`,
          template: `<a href="##statlink##" class="${btnClass} non-routed" target="_blank" rel="noopener" title="##description##" style="background-color: ##backgroundColor##; color: ##textColor##;"><img src="##image##" class="native-img">Sponsored by ##company##</a>`,
        })
      }
    }
  }, [serve, placement, btnClass])

  const refreshAds = e => {
    const except = document.querySelector(`.${btnClass}`)

    // e.stopPropagation()
    const hasClickedAd = except === e.target

    if (hasClickedAd) {
      // console.log('Clicked on the ad')
      e.stopPropagation()
      return
    }

    const isAdLoaded = carbonWrapper.current.hasChildNodes() && carbonWrapper.current.children.length

    // eslint-disable-next-line no-underscore-dangle
    if (window._bsa) {
      if (isAdLoaded) {
        // eslint-disable-next-line no-underscore-dangle
        window._bsa.reload(`.${mainClass}`)
      } else {
        initCarbon()
      }
    }
  }

  const handleClick = useCallback(
    throttle(refreshAds, BSA_REFRESH_INTERVAL, {
      trailing: false,
      leading: true,
    }),
    [],
  )

  useEffect(() => {
    initCarbon()
    const cWrapper = carbonWrapper.current

    timerID.current = setTimeout(() => document.addEventListener('click', handleClick), BSA_REFRESH_INTERVAL)

    return () => {
      clearTimeout(timerID.current)
      document.removeEventListener('click', handleClick)

      while (cWrapper.firstChild) {
        cWrapper.removeChild(cWrapper.firstChild)
      }
    }
  }, [handleClick, initCarbon])

  return <BsaWrapper className={classes} ref={carbonWrapper} />
}

CarbonCustom.propTypes = {
  serve: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  btnClass: PropTypes.string,
}

export default CarbonCustom
