import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// import { CodeView } from '..'
import { BtnLink } from '../Buttons'

import {
  BTN_ICON,
  DARK_GRAY,
  // CODE_H,
  GRAY,
  BORDER_RAD,
  ACCENT_COLOR,
  LIGHT,
  PAD_LR,
  PAD_LR_S,
  MEDIA,
} from '../../constants'

// Styled Components
import { BtnWrapper, CodeHeader } from '../../styles/layout'

const StyledModal = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${PAD_LR};

  @media ${MEDIA.SMALL} {
    padding: ${PAD_LR_S};
  }
`

const StyledPasteBox = styled.textarea`
  top: 40px;
  width: 100%;
  height: 80%;
  margin: 0;
  padding: 20px;
  border: 1px solid ${GRAY};
  border-bottom-left-radius: ${BORDER_RAD};
  border-bottom-right-radius: ${BORDER_RAD};
  border-top-right-radius: ${BORDER_RAD};
  background-color: ${DARK_GRAY};
  font-family: Menlo, Monaco, 'Courier New', monospace;
  font-size: 13px;
  color: ${LIGHT};
  line-height: 1.6;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  &:active,
  &:focus {
    border-color: ${ACCENT_COLOR};
    outline: none;
  }
`

const PasteSvgModal = ({ onApplyPasteSvg, disabled }) => {
  const [svgCode, setSvgCode] = useState('')

  const handleChange = e => {
    e.preventDefault()
    setSvgCode(e.target.value)
  }

  const onApply = e => {
    e.preventDefault()
    onApplyPasteSvg(svgCode)
    setTimeout(() => {
      setSvgCode('')
    }, 1000)
  }

  return (
    <StyledModal>
      <CodeHeader>Paste your SVG markup here</CodeHeader>
      <StyledPasteBox
        value={svgCode}
        onChange={handleChange}
        disabled={disabled}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        data-gramm="false"
      />
      <BtnWrapper>
        <BtnLink
          kind="big"
          text="Apply"
          icon={BTN_ICON.MARKUP}
          onClick={onApply}
        />
      </BtnWrapper>

      {/* <CodeView title="SVG Code" code={svgCode} syntax="svg" /> */}
    </StyledModal>
  )
}

PasteSvgModal.propTypes = {
  onApplyPasteSvg: PropTypes.func,
  disabled: PropTypes.bool,
}

export default PasteSvgModal
