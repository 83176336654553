import React from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'

import { IconMenu } from '../svgs'
import {
  PAD_LR,
  PAD_LR_S,
  LIGHT,
  MEDIA,
} from '../../constants'

import { BtnSmall } from '../../styles/layout'

const StyledBtnMenu = styled(BtnSmall)`
  position: absolute;
  /* right: ${PAD_LR}; */
  right: 20px;
  top: 11px;
  border: none;
  z-index: 2;
  transform: ${props => (props.shown ? 'translatex(0)' : 'translatex(0px)')};

  @media ${MEDIA.SMALL} {
    /* right: ${PAD_LR_S}; */
    right: 12px;
    top: 10px;
  }

  svg {
    transform: scale(1);
    width: 16px;
    height: 16px;

    .path1, .path3 {
      transition: transform .1s ease-out;
    }
  }

  &:hover {
    background: transparent;

    svg {
      transition: transform .3s ease-out;

      g, path {
        fill: ${LIGHT};
      }
      
      .path1, .path3 {
        transition: transform .1s ease-out;
      }
      .path1 {
        transform: scale(1) translateY(-4px);
      }
      
      .path3 {
        transform: scale(1) translateY(4px);
      }
    }
  }
  
`

const BtnMenu = ({ ...props }) => (
  <StyledBtnMenu {...props} title="Shown main navigation">
    <IconMenu />
  </StyledBtnMenu>
)

export default BtnMenu
