import React from 'react'
import PropTypes from 'prop-types'

// Components
import { BtnMenu } from '../Buttons'

// Styled Components
import {
  StyledHeader,
  LogoWrapper,
  Logo,
  // navLink,
} from '../../styles/layout'

const Header = ({ shown, toggleMenu }) => (
  <StyledHeader>
    <LogoWrapper>
      <Logo to="/">SVG Artista</Logo>
    </LogoWrapper>
    <BtnMenu shown={shown} onClick={toggleMenu} />
  </StyledHeader>
)

Header.propTypes = {
  shown: PropTypes.bool,
  toggleMenu: PropTypes.func,
}

export default Header
