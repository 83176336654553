// Increment or decrement the numerical value depending
// on whether we pressed up or down arrow key.
const getArrowBasedValue = ({
  key, shiftKey, metaKey, altKey, ctrlKey, target: { value: val },
}, step = 0.1) => {
  // Remove the 's' time unit from passed value
  const prevVal = parseFloat(val)

  // Calculate the actual step based on which modifier key we are pressing
  // eslint-disable-next-line no-nested-ternary
  const actualStep = shiftKey ? step * 10 : metaKey || ctrlKey ? step * 100 : altKey ? step / 10 : step

  // Will be undefined if other keys are pressed.
  // We cannot use || logic here, as '0' and negative
  // numbers are valid but are also 'falsy'!!!
  const newVal = {
    ArrowUp: prevVal + actualStep,
    ArrowDown: prevVal - actualStep,
  }[key]

  // If other keys are pressed return the original value,
  // otherwise return the new value, but first convert it to
  // fixed value to avoid floating point rouding errors
  return typeof newVal === 'undefined' ? prevVal : parseFloat(newVal.toFixed(10))
}

export default getArrowBasedValue
