import React from 'react'
import PropTypes from 'prop-types'

import {
  BTN_ICON,
} from '../../constants'

import {
  IconMarkup,
  IconUpload,
  IconCopy,
  IconPlay,
  IconCode,
  IconClose,
} from '../svgs'

import { BtnStyledLink } from '../../styles/layout'

const BtnLink = ({
  text, icon, codeShown, ...props
}) => (
  <BtnStyledLink {...props}>
    {({
      [BTN_ICON.MARKUP]: <IconMarkup />,
      [BTN_ICON.UPLOAD]: <IconUpload />,
      [BTN_ICON.COPY]: <IconCopy />,
      [BTN_ICON.PLAY]: <IconPlay />,
      [BTN_ICON.CODE]: <IconCode />,
      [BTN_ICON.CLOSE]: <IconClose />,
    }[icon])}
    <span>{text}</span>
  </BtnStyledLink>
)

BtnLink.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  codeShown: PropTypes.bool,
}

export default BtnLink
