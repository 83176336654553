import React from 'react'
import PropTypes from 'prop-types'

import { IconUpload } from '../svgs'

import { BtnInputLabel, BtnInput } from '../../styles/layout'

// Constants
import {
// BTN_BIG,
} from '../../constants'


const BtnOpenSvg = ({ label, ...rest }) => (
  <BtnInputLabel kind="big" title="Open SVG">
    <IconUpload />
    <span>{label}</span>
    <BtnInput type="file" {...rest} />
  </BtnInputLabel>
)

BtnOpenSvg.propTypes = {
  label: PropTypes.string,
}
export default BtnOpenSvg
