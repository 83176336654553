/* eslint-disable max-len */
import React from 'react'
// import { Link } from '@reach/router'
import styled from 'styled-components'

import {
  IconHart, IconTwitter, IconFacebook, IconLinkedin,
} from '../svgs'

import {
  LIGHT,
  BOOK_FONT,
  // LIGHT_FONT,
  // GRAY,
  LIGHTER_GRAY,
  // LIGHT_GRAY,
  ACCENT_COLOR,
  SOCIAL_SHARE_TITLE,
  SOCIAL_SHARE_TEXT,
  SOCIAL_SHARE_TOOLTIP,
  SITE_URL,
  // GRAY,
  // MEDIA,
} from '../../constants'

// import { NavLink } from '../../styles/layout'

const SocialWrapper = styled.div`
  display: inline-block;
  margin-left: 12px;

  /* @media screen and (max-width: 490px) {
    margin-left: 0;
    margin-top: 6px;
    width: 100%;
  } */
`

const SocialTitle = styled.h4`
  display: inline-block;
  font-family: ${BOOK_FONT};
  font-size: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${LIGHT};
  margin: 0;

  @media screen and (max-width: 490px) {
    margin-right: 0;
    font-size: 12px;
  }

  span {
    display: inline-block;
    color: ${LIGHTER_GRAY};
    /* opacity: .7; */
  }

  svg {
    height: 13px;
    width: auto;
    display: inline-block;
    margin-left: 7px;
    margin-bottom: 2px;
    animation: heartbeat 1.5s ease-in-out infinite both;

    path {
      fill: #ee3b7d;
    }
  }

  @keyframes heartbeat {
    from {
      transform: scale(1);
      transform-origin: center center;
      animation-timing-function: ease-out;
    }
    10% {
      transform: scale(0.91);
      animation-timing-function: ease-in;
    }
    17% {
      transform: scale(0.98);
      animation-timing-function: ease-out;
    }
    33% {
      transform: scale(0.87);
      animation-timing-function: ease-in;
    }
    45% {
      transform: scale(1);
      animation-timing-function: ease-out;
    }
  }
`

const SocialLinks = styled.div`
  position: relative;
  padding: 0;
  display: inline-block;

  /* @media screen and (max-width: 490px) {
    padding-left: 25px;
  } */

  &::before,
  &::after {
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    font-size: 16px;
    color: hsl(300, 1%, 60%);
  }

  &::before {
    position: static;
    content: '→';
    left: 0px;
    margin: 0 6px 0 12px;
  }

  a {
    padding: 0 8px;
    line-height: 1;

    @media screen and (max-width: 490px) {
      font-size: 12px;
    }

    &:hover {
      svg {
        transform: scale(1.2);
        fill: ${ACCENT_COLOR};
      }
    }
  }

  svg {
    height: 15px;
    width: auto;
    display: inline-block;
    margin-bottom: 2px;
    fill: ${LIGHT};
    transform: scale(1);
    transition: all 0.15s ease-out;
  }
`

const Social = () => (
  <SocialWrapper>
    <SocialTitle>
      <span>Share the</span>
      <IconHart />
    </SocialTitle>

    <SocialLinks>
      <a
        href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(SITE_URL)}&text=${SOCIAL_SHARE_TEXT}&via=ana108&hashtags=svg,css,animation`}
        title={`${SOCIAL_SHARE_TOOLTIP} on Twitter`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <IconTwitter />
      </a>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${SITE_URL}&p=${SOCIAL_SHARE_TITLE}`}
        title={`${SOCIAL_SHARE_TOOLTIP} on Facebook`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <IconFacebook />
      </a>
      <a
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(SITE_URL)}&title=${SOCIAL_SHARE_TITLE}`}
        title={`${SOCIAL_SHARE_TOOLTIP} on Linkedin`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <IconLinkedin />
      </a>
    </SocialLinks>
  </SocialWrapper>
)

export default Social
