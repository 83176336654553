import React from 'react'
import PropTypes from 'prop-types'
// import { SketchPicker } from 'react-color'

// Components
import { Input, Picker } from '..'

// Constants
import { BTN_ICON } from '../../constants'

// Styled Components
import {
  SidebarFieldset,
  SidebarButtons,
  // FieldsetHeader,
  // FieldsetTitle,
} from '../../styles/layout'

import { BtnOpenSvg, BtnLink, BtnColorPicker } from '../Buttons'

const MainPanel = ({
  svgElemClass,
  onChangeSvgElementClass,
  bgColor,
  onChangeBgColor,
  animationType,
  onChangeAnimationType,
  onChangeSvgFile,
  onClickPasteSvg,
  disabled,
}) => (
  <SidebarFieldset isOn>
    <SidebarButtons>
      <BtnOpenSvg label="Open SVG" accept=".svg" onChange={onChangeSvgFile} />
      <BtnLink kind="big" text="Paste Markup" icon={BTN_ICON.MARKUP} onClick={onClickPasteSvg} />
    </SidebarButtons>

    {/* <FieldsetHeader>
      <FieldsetTitle>General</FieldsetTitle>
    </FieldsetHeader> */}

    <Input label="element class:" value={svgElemClass} disabled={disabled} onChange={e => onChangeSvgElementClass(e.target.value)} />
    {/* <Input label="background color:" value={bgColor} disabled={disabled} onChange={e => onChangeBgColor(e.target.value)} /> */}
    {/* <SketchPicker color={bgColor} onChangeComplete={color => onChangeBgColor(color.hsl)} /> */}
    <BtnColorPicker color={bgColor} onChangeComplete={color => onChangeBgColor(color.hsl)} />

    <Picker
      label="animation type:"
      items={[
        {
          name: 'Transition',
          value: 'transition',
        },
        {
          name: 'Animation',
          value: 'animation',
        },
      ]}
      value={animationType}
      onChange={e => onChangeAnimationType(e.target.value)}
      disabled={disabled}
    />
  </SidebarFieldset>
)

MainPanel.propTypes = {
  svgElemClass: PropTypes.string,
  bgColor: PropTypes.string,
  animationType: PropTypes.string,
  disabled: PropTypes.bool,
  onChangeSvgElementClass: PropTypes.func,
  onChangeBgColor: PropTypes.func,
  onChangeAnimationType: PropTypes.func,
  onChangeSvgFile: PropTypes.func,
  onClickPasteSvg: PropTypes.func,
}

export default MainPanel
