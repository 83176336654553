import React from 'react'

const IconUpload = () => (

  <svg
    className="iconUpload"
    width="39px"
    height="44px"
    viewBox="0 0 39 44"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-1115.000000, -133.000000)" fill="#f2f2f2" fillRule="nonzero">
      <path d="M1115,164.705882 L1115,170.529412 C1115,173.745658 1117.60728,176.352941 1120.82353,176.352941 L1148,176.352941 C1151.21625,176.352941 1153.82353,173.745658 1153.82353,170.529412 L1153.82353,164.705882 C1153.82353,163.6338 1152.95444,162.764706 1151.88235,162.764706 C1150.81027,162.764706 1149.94118,163.6338 1149.94118,164.705882 L1149.94118,170.529412 C1149.94118,171.601494 1149.07208,172.470588 1148,172.470588 L1120.82353,172.470588 C1119.75145,172.470588 1118.88235,171.601494 1118.88235,170.529412 L1118.88235,164.705882 C1118.88235,163.6338 1118.01326,162.764706 1116.94118,162.764706 C1115.86909,162.764706 1115,163.6338 1115,164.705882 Z" id="Shape" />
      <path d="M1140.80385,144.72556 C1141.56193,145.483637 1142.79101,145.483637 1143.54909,144.72556 C1144.30717,143.967484 1144.30717,142.738399 1143.54909,141.980322 L1135.78438,134.215616 C1135.02631,133.45754 1133.79722,133.45754 1133.03915,134.215616 L1125.27444,141.980322 C1124.51636,142.738399 1124.51636,143.967484 1125.27444,144.72556 C1126.03252,145.483637 1127.2616,145.483637 1128.01968,144.72556 L1134.41176,138.333473 L1140.80385,144.72556 Z" id="Shape" />
      <path d="M1132.47059,135.588235 L1132.47059,162.764706 C1132.47059,163.836788 1133.33968,164.705882 1134.41176,164.705882 C1135.48385,164.705882 1136.35294,163.836788 1136.35294,162.764706 L1136.35294,135.588235 C1136.35294,134.516153 1135.48385,133.647059 1134.41176,133.647059 C1133.33968,133.647059 1132.47059,134.516153 1132.47059,135.588235 Z" id="Shape" />
    </g>
  </svg>
)

export default IconUpload
