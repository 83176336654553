const getAnimationCss = ({
  svgElemClass,
  strokeDasharrayStartValue,
  strokeDashoffsetStartValue,
  // animStrokeValue,
  // animFillValue,
  animValue,
  shouldAnimateFill,
  shouldAnimateStroke,
  strokeDashoffsetEndValue,
  fillEndValue,
  i,
}) => `
${
  shouldAnimateStroke
    ? `
@keyframes animate-svg-stroke-${i + 1} {
  0% {
    stroke-dashoffset: ${strokeDashoffsetStartValue}px;
    stroke-dasharray: ${strokeDasharrayStartValue}px;
  }
  100% {
    stroke-dashoffset: ${strokeDashoffsetEndValue}px;
    stroke-dasharray: ${strokeDasharrayStartValue}px;
  }
}`
    : ''
}
${
  shouldAnimateFill && fillEndValue
    ? `
@keyframes animate-svg-fill-${i + 1} {
    0% { fill: transparent }
  100% { fill: ${fillEndValue} }
}`
    : ''
}

.${svgElemClass}-${i + 1} {
  animation: ${animValue};
}
`

export default getAnimationCss
