import React from 'react'

const IconMenu = () => (

  <svg
    className="iconMenu"
    viewBox="0 0 50 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fillRule="nonzero" stroke="none" strokeWidth="1">
      <path className="path1" d="M1.88679245 4H48.1132075C49.154717 4 50 3.104 50 2s-.845283-2-1.8867925-2H1.88679245C.84528302 0 0 .896 0 2s.84528302 2 1.88679245 2z" />
      <path className="path2" d="M1.88679245 22H48.1132075C49.154717 22 50 21.104 50 20s-.845283-2-1.8867925-2H1.88679245C.84528302 18 0 18.896 0 20s.84528302 2 1.88679245 2z" />
      <path className="path3" d="M1.88679245 40H48.1132075C49.154717 40 50 39.104 50 38s-.845283-2-1.8867925-2H1.88679245C.84528302 36 0 36.896 0 38s.84528302 2 1.88679245 2z" />
    </g>
  </svg>

)

export default IconMenu
