import React from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'

// Constants
import { PAD_LR, MEDIA } from '../../constants'

const PHWrapper = styled.a`
  display: block;
  margin: 0;
  padding: 0;
  /* width: 270px; */
  position: absolute;
  left: ${PAD_LR};
  /* bottom: ${PAD_LR}; */
  bottom: 15px;

  @media ${MEDIA.SMALL} {
    display: none
  }
`

// ---------------------------------
// Featured on PH
const ProductHunt = () => (
  <PHWrapper
    href="https://www.producthunt.com/posts/svg-artista?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-svg-artista"
    target="_blank"
  >
    <img
      src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=165745&theme=dark"
      alt="SVG Artista - Little tool that helps you create SVG animations | Product Hunt Embed"
      style={{ width: '200px', height: '44px' }}
      width="200px"
      height="44px"
    />
  </PHWrapper>
)

// ---------------------------------
// #1 Product of the day on PH
// const ProductHunt = () => (
//   <PHWrapper
//     href="https://www.producthunt.com/posts/svg-artista?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-svg-artista"
//     target="_blank"
//   >
//     <img
//       src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=165745&theme=dark&period=daily"
//       alt="SVG Artista - Little tool that helps you create SVG animations | Product Hunt Embed"
//       style={{ width: '200px', height: '44px' }}
//       width="200px"
//       height="44px"
//     />
//   </PHWrapper>
// )

export default ProductHunt
