import React from 'react'

const IconClose = () => (

  <svg className="iconClose" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg">
    <g strokeWidth="4">
      <path d="M2 2l39.5979797 39.5979797" />
      <path d="M2 41.59798L41.5979797 2.0000003" />
    </g>
  </svg>

)

export default IconClose
