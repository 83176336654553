import React from 'react'
// import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { IconOptions } from '../svgs'

import { BtnSmall } from '../../styles/layout'
import {
  // PAD_LR,
  PAD_LR_S,
  MEDIA,
  EASE,
  DARKER_GRAY,
} from '../../constants'

const btnOptionsHide = css`
  transform: translateY(-180%);
  transition: transform .3s ${EASE.easeOutQuad} .2s;
`

const btnOptionsShow = css`
  transform: translateY(0);
  transition: transform .3s ${EASE.easeOutQuad} .17s;
`

const StyledBtnOptions = styled(BtnSmall)`
  position: absolute;
  top: 20px;
  left: ${PAD_LR_S};
  z-index: 1;
  display: none;
  border-color: hsla(0, 0%, 100%, 0);
  background-color: ${DARKER_GRAY};
  ${props => (props.codeShown ? btnOptionsHide : btnOptionsShow)};
  display: flex;

  /* @media ${MEDIA.SMALL} {
    display: flex;
  } */
  svg {
    height: 12px;
  }
`

const BtnOptions = ({ ...props }) => (
  <StyledBtnOptions {...props} title="Open Controls">
    <IconOptions />
  </StyledBtnOptions>
)

export default BtnOptions
