import React from 'react'


const IconMarkup = () => (

  <svg
    className="iconMarkup"
    width="62px"
    height="70px"
    viewBox="0 0 62 70"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-816.000000, -213.000000)" fill="#f2f2f2" fillRule="nonzero">
        <g transform="translate(816.000000, 213.000000)">
          <path d="M44.1958763,0 L6.34020619,0 C2.87010309,0 0.0309278351,2.78181818 0.0309278351,6.18181818 L0.0309278351,49.4545455 L6.34020619,49.4545455 L6.34020619,6.18181818 L44.1958763,6.18181818 L44.1958763,0 Z M55.6597938,14.3636364 L20.9587629,14.3636364 C17.4886598,14.3636364 14.6494845,17.1454545 14.6494845,20.5454545 L14.6494845,63.8181818 C14.6494845,67.2181818 17.4886598,70 20.9587629,70 L55.6597938,70 C59.1298969,70 61.9690722,67.2181818 61.9690722,63.8181818 L61.9690722,20.5454545 C61.9690722,17.1454545 59.1298969,14.3636364 55.6597938,14.3636364 Z" />
        </g>
      </g>
    </g>
  </svg>
)

export default IconMarkup
