import IconCode from './IconCode'
import IconOptions from './IconOptions'
import IconPlay from './IconPlay'
import IconMenu from './IconMenu'
import IconUpload from './IconUpload'
import IconMarkup from './IconMarkup'
import IconClose from './IconClose'
import IconCopy from './IconCopy'
import IconHart from './IconHart'
import IconTwitter from './IconTwitter'
import IconFacebook from './IconFacebook'
import IconLinkedin from './IconLinkedin'

export {
  IconCode,
  IconOptions,
  IconPlay,
  IconMenu,
  IconUpload,
  IconMarkup,
  IconClose,
  IconCopy,
  IconHart,
  IconTwitter,
  IconFacebook,
  IconLinkedin,
}
