import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import {
  SITE_TITLE,
  SITE_DESCRIPTION,
  SITE_TITLE_DIVIDER,
  SITE_URL,
  OG_IMAGE_TYPE,
  FACEBOOK_APP_ID,
  TWITTER_ACCOUNT,
  SITE_IMAGE,
  SITE_KEYWORDS,
} from '../../constants'

const Seo = ({
  title, description, keywords, image, slug,
}) => {
  const actualTitle = title !== '' ? `${SITE_TITLE} ${SITE_TITLE_DIVIDER} ${title}` : SITE_TITLE
  const actualDescription = description || SITE_DESCRIPTION
  const actualKeywords = keywords ? `${[[...keywords], [...SITE_KEYWORDS]].join(',')}` : SITE_KEYWORDS
  const actualImage = image || SITE_IMAGE
  const url = slug ? `${SITE_URL}/${slug}` : SITE_URL

  return (
    <Helmet>
      <title>{actualTitle}</title>
      <meta name="description" content={actualDescription} />
      <meta name="keywords" content={actualKeywords} />
      <meta name="author" content="co-authored by Ana Travas and Sergej Skrjanec a.k.a. C2" />
      <link rel="author" href="//wearec2.com" />
      {/* OpenGraph tags */}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={actualTitle} />
      <meta property="og:description" content={actualDescription} />
      <meta property="og:image" content={actualImage} />
      <meta property="og:image:type" content={OG_IMAGE_TYPE || 'image/gif'} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US" />
      <meta property="fb:app_id" content={FACEBOOK_APP_ID} />
      {/* Twitter Card tags */}
      <meta name="twitter:url" content={SITE_URL} />
      <meta name="twitter:title" content={actualTitle} />
      <meta name="twitter:site" content={TWITTER_ACCOUNT || ''} />
      <meta name="twitter:description" content={actualDescription} />
      <meta name="twitter:image" content={actualImage} />
      {/* <meta name="twitter:card" content="summary" /> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={TWITTER_ACCOUNT || ''} />
    </Helmet>
  )
}

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.array,
  image: PropTypes.string,
  slug: PropTypes.string,
}

export default Seo
