import React, { useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import Prism from 'prismjs'

import '../../prism-xonokai.css'

// import

const CodeView = memo(({ title, code, syntax }) => {
  useEffect(() => {
    Prism.highlightAll()
  }, [code, syntax])

  const codeProps = {
    ...(syntax && {
      className: `language-${syntax}`,
    }),
  }

  return (
    <>
      <h4>{title}</h4>
      <div>
        {code ? (
          <pre>
            <code {...codeProps}>{code}</code>
          </pre>
        ) : (
          <i>nothing yet...</i>
        )}
      </div>
    </>
  )
})

CodeView.propTypes = {
  title: PropTypes.string,
  code: PropTypes.string,
  syntax: PropTypes.string,
}

export default CodeView
