import React from 'react'
import PropTypes from 'prop-types'

// Styled Components
import {
  ToggleDrawWrapper,
  ToggleDrawInput,
  ToggleLabel,
} from '../../styles/layout'


const ToggleDraw = ({ label, id, ...rest }) => (
  <ToggleDrawWrapper>
    <ToggleDrawInput id={id} {...rest} />
    <ToggleLabel htmlFor={id}>
      {label}
    </ToggleLabel>
  </ToggleDrawWrapper>
)

ToggleDraw.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
}

export default ToggleDraw
