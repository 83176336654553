import React from 'react'

const IconFacebook = () => (

  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="50" viewBox="0 0 29 50">
    <path d="M27,0 L19.6363636,0 C12.8583235,0 7.36363636,5.372583 7.36363636,12 L7.36363636,19.2 L0,19.2 L0,28.8 L7.36363636,28.8 L7.36363636,48 L17.1818182,48 L17.1818182,28.8 L24.5454545,28.8 L27,19.2 L17.1818182,19.2 L17.1818182,12 C17.1818182,10.6745166 18.2807556,9.6 19.6363636,9.6 L27,9.6 L27,0 Z" transform="translate(1 1)" />
  </svg>
)

export default IconFacebook
